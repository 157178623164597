import styles from './TrialContent.module.scss';
import React, { useEffect, useState } from 'react';
import Card from '@moonshineragency/ui/src/components/Card/Card';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import { Separator } from 'reakit/Separator';
import Link from '@moonshineragency/ui/src/components/Link/Link';

const renderSection = (title, value) => (
  <>
    <Content className={styles.headline} theme="neutral" size="uiText75">
      {title}
    </Content>
    <Content
      className={styles.text}
      variant="light"
      theme="neutral"
      size="uiText75"
    >
      {value}
    </Content>
  </>
);
const convertToLowercaseKeys = obj =>
  Object.keys(obj).reduce((acc, key) => {
    acc[key.toLowerCase()] = obj[key];
    return acc;
  }, {});

const renderSectionByTabID = (values, tabId) => {
  const newPrimaryMeasures = values?.primary_outcome_measures
    ? values?.primary_outcome_measures?.map(item =>
        convertToLowercaseKeys(item),
      )
    : [];

  // filter out duplicated outcome measures e.g NCT04632290
  const primaryKey = 'primaryoutcomemeasure';
  const uniqueNewPrimaryMeasures = [
    ...new Map(
      newPrimaryMeasures.map(item => [item[primaryKey], item]),
    ).values(),
  ];

  const newSecondaryMeasures = values?.secondary_outcome_measures
    ? values?.secondary_outcome_measures?.map(item =>
        convertToLowercaseKeys(item),
      )
    : [];

  const secondaryKey = 'secondaryoutcomemeasure';
  const uniqueNewSecondaryMeasures = [
    ...new Map(
      newSecondaryMeasures.map(item => [item[secondaryKey], item]),
    ).values(),
  ];

  const newArmValues = values?.arms
    ? values?.arms?.map(item => convertToLowercaseKeys(item))
    : [];

  const newInterventionValues = values?.interventions
    ? values?.interventions?.map(item => convertToLowercaseKeys(item))
    : [];
  switch (tabId) {
    case 'general':
      return (
        <>
          {renderSection('Brief title', values?.brief_title)}
          <Separator className={styles.separator} />
          {renderSection('Official title', values?.official_title)}
          <Separator className={styles.separator} />
          {renderSection('Brief description', values?.brief_description)}
        </>
      );
    case 'eligibility':
      return (
        <>
          {renderSection('Gender/Sex', values?.sex)}
          <Separator className={styles.separator} />
          {renderSection('Age', values?.age)}
          <Separator className={styles.separator} />
          {values?.inclusion_criteria &&
            renderSection(
              'Inclusion criteria',
              values?.inclusion_criteria?.map(item => (
                <ul key={item}>
                  <li>{item}</li>
                </ul>
              )),
            )}
          <Separator className={styles.separator} />
          {values?.exclusion_criteria &&
            renderSection(
              'Exclusion criteria',
              values?.exclusion_criteria?.map(item => (
                <ul key={item}>
                  <li>{item}</li>
                </ul>
              )),
            )}
        </>
      );
    case 'trial-type':
      return (
        <>
          {renderSection(
            'Interventions',
            newInterventionValues?.map(item => (
              <ul key={`${item.interventiontype}-${item.interventionname}`}>
                <li>
                  {item.interventiontype}: {item.interventionname}
                  <br />
                  {item.interventiondescription}
                </li>
              </ul>
            )),
          )}
          <Separator className={styles.separator} />
          {renderSection(
            'Arms',
            newArmValues?.map(item => (
              <ul key={`${item.armgrouptype}-${item.armgrouplabel}`}>
                <li>
                  {item.armgrouptype}: {item.armgrouplabel}
                  <br />
                  {item.armgroupdescription}
                </li>
              </ul>
            )),
          )}
        </>
      );
    case 'potential-benefits':
      return (
        <>
          {renderSection(
            'Primary outcome measures',
            uniqueNewPrimaryMeasures?.map(measure => {
              return (
                <Content
                  variant="light"
                  size="uiText75"
                  key={`${measure.primaryoutcomemeasure}-${measure.primaryoutcometimeframe}`}
                >
                  Measure: {measure.primaryoutcomemeasure}
                  <br />
                  Time Frame: {measure.primaryoutcometimeframe}
                  <br />
                  Description: {measure.primaryoutcomedescription}
                </Content>
              );
            }),
          )}
          <Separator className={styles.separator} />
          {renderSection(
            'Secondary outcome measures',
            uniqueNewSecondaryMeasures?.map(measure => {
              return (
                <Content
                  variant="light"
                  size="uiText75"
                  key={`${measure.secondaryoutcomemeasure}-${measure.secondaryoutcometimeframe}`}
                >
                  Measure: {measure.secondaryoutcomemeasure}
                  <br />
                  Time Frame: {measure.secondaryoutcometimeframe}
                  <br />
                  Description: {measure.secondaryoutcomedescription}
                </Content>
              );
            }),
          )}
        </>
      );

    case 'outcome-measures':
      return (
        <>
          {renderSection(
            'Primary outcome measures',
            uniqueNewPrimaryMeasures?.map(measure => {
              return (
                <Content
                  variant="light"
                  size="uiText75"
                  key={`${measure.primaryoutcomemeasure}-${measure.primaryoutcometimeframe}`}
                >
                  Measure: {measure.primaryoutcomemeasure}
                  <br />
                  Time Frame: {measure.primaryoutcometimeframe}
                  <br />
                  Description: {measure.primaryoutcomedescription}
                </Content>
              );
            }),
          )}
          <Separator className={styles.separator} />
          {renderSection(
            'Secondary outcome measures',
            uniqueNewSecondaryMeasures?.map(measure => {
              return (
                <Content
                  variant="light"
                  size="uiText75"
                  key={`${measure.secondaryoutcomemeasure}-${measure.secondaryoutcometimeframe}`}
                >
                  Measure: {measure.secondaryoutcomemeasure}
                  <br />
                  Time Frame: {measure.secondaryoutcometimeframe}
                  <br />
                  Description: {measure.secondaryoutcomedescription}
                </Content>
              );
            }),
          )}
        </>
      );
    default:
      return null;
  }
};

// TODO: render different sections for different tabs on the left
const TrialContent = ({ form, tabInfo }) => {
  const [selectedTabId, selectTabId] = useState(tabInfo.selectedId);
  useEffect(() => {
    selectTabId(tabInfo.selectedId);
  }, [tabInfo.selectedId]);

  return (
    <Card className={styles.wrapper}>
      <div className={styles.header}>
        <Heading as="h3" size="h4" theme="primary">
          <div>Source: clinicaltrials.gov</div>
        </Heading>
        <Link
          asType="external"
          to={`https://clinicaltrials.gov/ct2/history/${form?.values?.id}`}
          target="_blank"
          className={styles.link}
          type="ui"
          size="75"
        >
          Show all versions
        </Link>
      </div>
      <Separator className={styles.separator} />
      <div>{renderSectionByTabID(form.values, selectedTabId)}</div>
    </Card>
  );
};
export default TrialContent;
