import styles from './FormCheckboxGroup.module.scss';
import React from 'react';

import {
  unstable_FormGroup as FormGroup,
  unstable_FormLabel as FormLabel,
  unstable_FormCheckbox as FormCheckbox,
} from 'reakit/Form';
import Checkbox from '@moonshineragency/ui/src/components/Checkbox/Checkbox';

const RadioGroup = ({ name, title, items = [], ...form }) => {
  return (
    <FormGroup {...form} className={styles.container} name={name}>
      <FormLabel {...form} as="legend" name={name} className={styles.legend}>
        {title}
      </FormLabel>
      <div className={styles.items}>
        {items.map(({ value, label: itemLabel }) => (
          <FormCheckbox
            as={Checkbox}
            {...form}
            value={value}
            name={name}
            label={itemLabel}
            align="top"
          />
        ))}
      </div>
    </FormGroup>
  );
};

export { RadioGroup as default };
