import styles from './TrialCompare.module.scss';
import General from 'components/admin/trialEdit/TrialCompare/components/General/General';
import TrialType from 'components/admin/trialEdit/TrialCompare/components/TrialType/TrialType';
import PotentialBenefits from 'components/admin/trialEdit/TrialCompare/components/PotentialBenefits/PotentialBenefits';
import OutcomeMeasures from 'components/admin/trialEdit/TrialCompare/components/OutcomeMeasures/OutcomeMeasures';
import Eligibility from 'components/admin/trialEdit/TrialCompare/components/Eligibility/Eligibility';
import Container from 'components/Container/Container';
import TrialComments from 'sections/admin/trialEdit/TrialComments';
import Client from 'api/client';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import Card from '@moonshineragency/ui/src/components/Card/Card';
import { useTabState, Tab, TabList } from 'reakit/Tab';
import classNames from 'classnames';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import {
  useDialogState,
  Dialog,
  DialogDisclosure,
  DialogBackdrop,
} from 'reakit/Dialog';
import React, { useEffect, useState } from 'react';

const fetchTrialVersions = async (
  nctId,
  versionOne,
  versionTwo,
  setTrialOlderVersion,
  setTrialNewerVersion,
) => {
  const { data } = await Client.admin.trials.versions(
    nctId,
    versionOne,
    versionTwo,
  );

  const trialOlderVersion = data[0].trial ?? {};
  const trialNewerVersion = data[1].trial ?? {};

  const injuryUnitMap = {
    hours: 'Hours',
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
    years: 'Years',
  };

  const mapTrialVersion = trialVersion => {
    return {
      ...trialVersion,
      ais: [
        trialVersion.ais_a ? 'ais_a' : null,
        trialVersion.ais_b ? 'ais_b' : null,
        trialVersion.ais_c ? 'ais_c' : null,
        trialVersion.ais_d ? 'ais_d' : null,
        trialVersion.ais_e ? 'ais_e' : null,
      ],
      injuries: trialVersion.injuries
        ? trialVersion.injuries.map(injury => injury.id)
        : [],
      time_since_injury_from_unit:
        injuryUnitMap[trialVersion.time_since_injury_from_unit],
      time_since_injury_to_unit:
        injuryUnitMap[trialVersion.time_since_injury_to_unit],
    };
  };

  const mappedTrialOlderVersion = mapTrialVersion(trialOlderVersion);
  const mappedTrialNewerVersion = mapTrialVersion(trialNewerVersion);

  setTrialOlderVersion(mappedTrialOlderVersion);
  setTrialNewerVersion(mappedTrialNewerVersion);
};

const TrialCompare = ({
  nctId,
  trial,
  injuriesData,
  interventionsData,
  benefitsData,
  mechanismsData,
  versions,
  versionToCompare,
  versionToCompareAgainst,
  trialCommentsData,
  trialForm,
}) => {
  const dialog = useDialogState();
  const tabInfo = useTabState();
  const [trialOlderVersion, setTrialOlderVersion] = useState({});
  const [trialNewerVersion, setTrialNewerVersion] = useState({});
  const [olderVersionNumber, setOlderVersionNumber] = useState(1);
  const [newerVersionNumber, setNewerVersionNumber] = useState(1);

  useEffect(() => {
    if (versions?.length > 1) {
      const newerVersion = versionToCompare || versions[versions?.length - 1];
      const olderVersion =
        versionToCompareAgainst || versions[versions?.length - 2];
      setNewerVersionNumber(newerVersion);
      setOlderVersionNumber(olderVersion);
    }
  }, [versions]);

  useEffect(() => {
    if (dialog.visible) {
      fetchTrialVersions(
        nctId,
        olderVersionNumber,
        newerVersionNumber,
        setTrialOlderVersion,
        setTrialNewerVersion,
      );
    }
  }, [olderVersionNumber, newerVersionNumber, dialog.visible]);

  return (
    <>
      <DialogDisclosure {...dialog} className={styles.dialogLabel}>
        Compare
      </DialogDisclosure>
      <DialogBackdrop className={styles.backdrop} {...dialog}>
        <Dialog {...dialog} className={styles.modal} aria-label="Welcome">
          <nav className={styles.nav}>
            <Heading size="h4" theme="primary" className={styles.heading}>
              Compare versions
            </Heading>
            <span>
              <IconWrapper
                Icon={IconComponents.SmallCrossXIcon}
                className={styles.closeIcon}
                onClick={() => dialog.hide()}
              />
            </span>
          </nav>
          <Container size="wide">
            <div className={styles.header}>
              <Heading size="h2" theme="primary">
                {trial.brief_title}
              </Heading>
              <div className={styles.infoSectionWrapper}>
                <div className={styles.infoSection}>
                  <Content
                    size="uiText50"
                    theme="neutral"
                    className={styles.infoSectionLabel}
                  >
                    NCT ID
                  </Content>
                  <Content
                    size="uiText75"
                    theme="neutral"
                    className={styles.infoSectionValue}
                  >
                    {trial.nct_id}
                  </Content>
                </div>
                <div className={styles.infoSectionDivider} />
                <div className={styles.infoSection}>
                  <Content
                    size="uiText50"
                    theme="neutral"
                    className={styles.infoSectionLabel}
                  >
                    Curation status
                  </Content>
                  <Content
                    size="uiText75"
                    theme="neutral"
                    className={styles.infoSectionValue}
                  >
                    {trial.curation_status}
                  </Content>
                </div>
              </div>
            </div>
            <Card className={styles.card}>
              <TabList
                className={styles.tabs}
                {...tabInfo}
                aria-label="Study Editor"
              >
                <Tab
                  {...tabInfo}
                  className={classNames(styles.tab, {
                    [styles.tabActive]: tabInfo.selectedId === 'general',
                  })}
                  id="general"
                >
                  <Content noSpacing theme="neutral" size="uiText100">
                    General
                  </Content>
                </Tab>
                <Tab
                  {...tabInfo}
                  className={classNames(styles.tab, {
                    [styles.tabActive]: tabInfo.selectedId === 'eligibility',
                  })}
                  id="eligibility"
                >
                  <Content noSpacing theme="neutral" size="uiText100">
                    Eligibility
                  </Content>
                </Tab>
                <Tab
                  {...tabInfo}
                  className={classNames(styles.tab, {
                    [styles.tabActive]: tabInfo.selectedId === 'trial-type',
                  })}
                  id="trial-type"
                >
                  <Content noSpacing theme="neutral" size="uiText100">
                    Trial type
                  </Content>
                </Tab>
                <Tab
                  {...tabInfo}
                  className={classNames(styles.tab, {
                    [styles.tabActive]:
                      tabInfo.selectedId === 'potential-benefits',
                  })}
                  id="potential-benefits"
                >
                  <Content noSpacing theme="neutral" size="uiText100">
                    Potential benefits
                  </Content>
                </Tab>
                <Tab
                  {...tabInfo}
                  className={classNames(styles.tab, {
                    [styles.tabActive]:
                      tabInfo.selectedId === 'outcome-measures',
                  })}
                  id="outcome-measures"
                >
                  <Content noSpacing theme="neutral" size="uiText100">
                    Outcome measures
                  </Content>
                </Tab>
              </TabList>
              <General
                trialOlderVersion={trialOlderVersion}
                trialNewerVersion={trialNewerVersion}
                tab={tabInfo}
                olderVersionNumber={olderVersionNumber}
                newerVersionNumber={newerVersionNumber}
                setOlderVersionNumber={setOlderVersionNumber}
                setNewerVersionNumber={setNewerVersionNumber}
              />
              <Eligibility
                tab={tabInfo}
                injuriesData={injuriesData}
                trialOlderVersion={trialOlderVersion}
                trialNewerVersion={trialNewerVersion}
                olderVersionNumber={olderVersionNumber}
                newerVersionNumber={newerVersionNumber}
                setOlderVersionNumber={setOlderVersionNumber}
                setNewerVersionNumber={setNewerVersionNumber}
              />
              <TrialType
                tab={tabInfo}
                trialOlderVersion={trialOlderVersion}
                trialNewerVersion={trialNewerVersion}
                interventionsData={interventionsData}
                olderVersionNumber={olderVersionNumber}
                newerVersionNumber={newerVersionNumber}
                setOlderVersionNumber={setOlderVersionNumber}
                setNewerVersionNumber={setNewerVersionNumber}
              />
              <PotentialBenefits
                tab={tabInfo}
                trialOlderVersion={trialOlderVersion}
                trialNewerVersion={trialNewerVersion}
                benefitsData={benefitsData}
                mechanismsData={mechanismsData}
                olderVersionNumber={olderVersionNumber}
                newerVersionNumber={newerVersionNumber}
                setOlderVersionNumber={setOlderVersionNumber}
                setNewerVersionNumber={setNewerVersionNumber}
              />
              <OutcomeMeasures
                tab={tabInfo}
                trialOlderVersion={trialOlderVersion}
                trialNewerVersion={trialNewerVersion}
                benefitsData={benefitsData}
                mechanismsData={mechanismsData}
                olderVersionNumber={olderVersionNumber}
                newerVersionNumber={newerVersionNumber}
                setOlderVersionNumber={setOlderVersionNumber}
                setNewerVersionNumber={setNewerVersionNumber}
              />
            </Card>
            <Card className={styles.comments}>
              <Content
                noSpacing
                theme="contrast"
                size="uiText100"
                className={styles.commentsTitle}
              >
                Comments
              </Content>
              <TrialComments
                trialCommentsData={trialCommentsData}
                trialForm={trialForm}
              />
            </Card>
          </Container>
        </Dialog>
      </DialogBackdrop>
    </>
  );
};

export default TrialCompare;
