import styles from './InputMockup.module.scss';
import React from 'react';
import classNames from 'classnames';

const InputMockup = ({ value, changedNew, changedOld }) => {
  return (
    <span
      className={classNames(styles.wrapper, {
        [styles.changedNew]: changedNew,
        [styles.changedOld]: changedOld,
      })}
    >
      <input className={styles.input} value={value} disabled />
    </span>
  );
};

export default InputMockup;
