/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable camelcase */
import styles from './Header.module.scss';
import Client from 'api/client';
import BackNavigation from 'components/Navigation/BackNavigation';
import PrivateElement from 'pages/admin/components/PrivateElement';
import NotificationToast from 'components/NotificationToast/NotificationToast';
import CuratorModal from 'components/admin/trialEdit/CuratorModal/CuratorModal';
import useStore from 'store';
import { useDialogState, DialogDisclosure } from 'reakit/Dialog';

import React, { useState, useEffect } from 'react';
import {
  unstable_FormSubmitButton as FormSubmitButton,
  unstable_Form as Form,
} from 'reakit/Form';
import Modal from '@moonshineragency/ui/src/components/Modal/Modal';

import Link from '@moonshineragency/ui/src/components/Link/Link';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import Button from '@moonshineragency/ui/src/components/Button/Button';

import classNames from 'classnames';
import { getDate, getYear, getHours, getMinutes } from 'date-fns';
import InputField from '@moonshineragency/ui/src/components/InputField/InputField';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';

const getAllCurators = async setAllCurators => {
  const { data: usersData } = await Client.admin.usersList.get();

  const userData = usersData.filter(user => {
    const userRoles = user.role;
    return userRoles.indexOf('editor') !== -1;
  });

  const mappedUserData = userData.map(user => {
    return {
      label: `${user.first_name} ${user.last_name}`,
      value: user.id,
    };
  });

  setAllCurators(mappedUserData);
};

const Header = ({
  trial: {
    nct_id,
    curation_status,
    published_at,
    modified_at,
    curator1,
    curator2,
    curator1_approved,
    curator2_approved,
  },
  rawTrial: { brief_title, imported_at, updated_at },
  form,
  isPublishable,
  isSavable,
  isCurationStartable,
  curationStarted,
  formattedTrial,
  handleTrialChange,
  isFormTrialChanged,
  setTrialData,
  permissions,
  isForcePublishable,
  setPermissions,
  getTrialData,
  setIsFormTrialChanged,
  unsaveableDialog,
}) => {
  const [edit] = useState(false);
  const [allCurators, setAllCurators] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const curatorApprovalDialog = useDialogState();
  const curatorDisapprovalDialog = useDialogState();
  const bothCuratorsApprovalDialog = useDialogState();
  const publishDialog = useDialogState();
  const unpublishableDialog = useDialogState();
  const unstartableDialog = useDialogState();
  const curatorFailedDialog = useDialogState();
  const discardCurationNotificationDialog = useDialogState();
  const discardCurationFailedNotificationDialog = useDialogState();
  const discardCurationPopoverDialog = useDialogState();
  const discardCurationAndPublishUncuratedDialog = useDialogState();
  const failedDiscardCurationAndPublishUncuratedDialog = useDialogState();

  const store = useStore();
  const user = store?.user;

  useEffect(() => {
    if (curation_status === 'started' || curation_status === 'curated') {
      getAllCurators(setAllCurators);
    }
  }, [curation_status]);

  const getCuratorNameById = id => {
    const curator = allCurators.find(c => {
      return c.value === id;
    });

    return curator?.label;
  };

  const renderInfoKeyValue = (key, label, bordered = true, primary = false) => (
    <div
      className={classNames(styles.infoSection, {
        [styles.bordered]: bordered,
      })}
    >
      <Content size="uiText50" theme="neutral" className={styles.label}>
        {key}
      </Content>
      <Content
        size="uiText75"
        theme="neutral"
        className={classNames(styles.value, {
          [styles.primary]: primary,
        })}
      >
        {label}
      </Content>
    </div>
  );

  const isLoading =
    !published_at && !modified_at && !imported_at && !updated_at;

  const publishedDate = published_at !== null ? new Date(published_at) : '';
  const modifiedDate = modified_at !== null ? new Date(modified_at) : '';
  const importedDate = imported_at !== null ? new Date(imported_at) : '';
  const updatedDate = updated_at !== null ? new Date(updated_at) : '';

  const getDateInfo = date => {
    if (!date) {
      return 'Never';
    }
    return (
      <>
        <span>
          {getDate(date)}{' '}
          {date.toLocaleString('en-us', {
            month: 'short',
          })}{' '}
          {getYear(date)}
        </span>
        <br />
        <span>
          {getHours(date)}:
          {(getMinutes(date) < 10 ? '0' : '') + getMinutes(date)}
        </span>
      </>
    );
  };

  const resetFormValues = trial => {
    Object.keys(trial).forEach(key => {
      form.update(key, trial[key]);
    });
  };

  const handlePublishSubmit = async () => {
    const { data } = await Client.admin.trials.publish(nct_id).catch(() => {
      unpublishableDialog.show();
    });

    setTrialData({
      trial: data.trial,
      rawTrial: data.rawTrial,
    });

    setPermissions({
      ...permissions,
      ...data.permissions,
    });

    publishDialog.show();
  };

  const handleStartCuration = async () => {
    let actionBarSaved = isSavable;

    const { data } = await Client.admin.trials.curation
      .update(nct_id, { curation_status: 'started' })
      .catch(() => {
        unstartableDialog.show();
      });

    setTrialData({
      trial: data.trial,
      rawTrial: data.rawTrial,
    });

    setPermissions({
      ...permissions,
      ...data.permissions,
    });

    actionBarSaved = false;

    setIsFormTrialChanged(actionBarSaved);
  };

  const handleApprove = async () => {
    const { data } = await Client.admin.trials.curation
      .approve(nct_id)
      .catch(() => {
        curatorDisapprovalDialog.show();
      });

    setTrialData({
      trial: data.trial,
      rawTrial: data.rawTrial,
    });

    setPermissions({
      ...permissions,
      ...data.permissions,
    });

    if (data?.trial?.curator1_approved && data?.trial?.curator2_approved) {
      bothCuratorsApprovalDialog.show();
    } else {
      curatorApprovalDialog.show();
    }
  };

  const handleModalCancel = () => {
    discardCurationPopoverDialog.hide();
    setShowErrorMessage(false);
    form.update('discard', '');
  };

  useEffect(() => {
    if (!discardCurationPopoverDialog.visible) {
      handleModalCancel();
    }
  }, [discardCurationPopoverDialog.visible]);

  const handleDiscardOnly = async () => {
    if (form.values.discard !== 'DISCARD') {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
      let actionBarSaved = isSavable;

      const { data } = await Client.admin.trials.curation
        .update(nct_id, { curation_status: 'uncurated' })
        .catch(() => {
          discardCurationFailedNotificationDialog.show();
        });

      setPermissions({
        ...permissions,
        ...data.permissions,
      });

      setTrialData({
        trial: data.trial,
        rawTrial: data.rawTrial,
      });

      discardCurationNotificationDialog.show();

      actionBarSaved = false;

      discardCurationPopoverDialog.hide();
      setIsFormTrialChanged(actionBarSaved);
      form.update('discard', '');
    }
  };

  const handleDiscardAndPublishUncurated = async () => {
    if (form.values.discard !== 'DISCARD') {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
      let actionBarSaved = isSavable;

      const { data } = await Client.admin.trials.curation
        .update(nct_id, { curation_status: 'uncurated' })
        .catch(() => {
          failedDiscardCurationAndPublishUncuratedDialog.show();
        });

      setPermissions({
        ...permissions,
        ...data.permissions,
      });

      setTrialData({
        trial: data.trial,
        rawTrial: data.rawTrial,
      });

      actionBarSaved = false;

      const res = await Client.admin.trials.publish(nct_id).catch(() => {
        unpublishableDialog.show();
      });

      if (res?.status === 200) {
        await getTrialData(nct_id, setTrialData, setPermissions, permissions);
      }
      discardCurationPopoverDialog.hide();
      discardCurationAndPublishUncuratedDialog.show();
      setIsFormTrialChanged(actionBarSaved);
    }
    form.update('discard', '');
  };

  return (
    <div>
      <div className={styles.actionsWrapper}>
        <BackNavigation
          className={styles.backNavContainer}
          label="All trials"
          to="/admin/trials/list"
        />
        <NotificationToast
          dialog={curatorApprovalDialog}
          title={
            curator1?.id === user?.user?.id
              ? `${curator2?.first_name} will be notified`
              : `${curator1?.first_name} will be notified`
          }
          theme="checkmark"
          variant="button"
        >
          We&apos;ll let this person know you approved the curation.
        </NotificationToast>

        <NotificationToast
          dialog={bothCuratorsApprovalDialog}
          title="Both curators have approved"
          theme="checkmark"
          variant="button"
        >
          We&apos;ll notify all admins and assigned curators.
        </NotificationToast>

        <NotificationToast
          dialog={publishDialog}
          title="Trial published"
          theme="checkmark"
          variant="button"
        />

        <NotificationToast
          dialog={discardCurationFailedNotificationDialog}
          title="Could not discard curation"
          theme="warning"
          variant="button"
        >
          You can&apos;t discard the curation at this state.
        </NotificationToast>

        <NotificationToast
          dialog={failedDiscardCurationAndPublishUncuratedDialog}
          title="Could not discard curation and publish uncurated trial"
          theme="warning"
          variant="button"
        >
          You can&apos;t discard the curation and publish uncurated trial at
          this state.
        </NotificationToast>

        <NotificationToast
          dialog={curatorDisapprovalDialog}
          title="Could not approve curation"
          theme="warning"
          variant="button"
        >
          You can&apos;t approve the trial at this state.
        </NotificationToast>

        <NotificationToast
          dialog={unpublishableDialog}
          title="Could not publish trial"
          theme="warning"
          variant="button"
        >
          You can&apos;t publish the trial at this state.
        </NotificationToast>

        <NotificationToast
          dialog={unstartableDialog}
          title="Could not start curation"
          theme="warning"
          variant="button"
        >
          You can&apos;t start the curation at this state.
        </NotificationToast>

        <NotificationToast
          dialog={unsaveableDialog}
          title="Could not save curation"
          theme="warning"
          variant="button"
        >
          You can&apos;t save the curation at this state.
        </NotificationToast>

        <NotificationToast
          dialog={curatorFailedDialog}
          title="Could not save curators"
          theme="warning"
          variant="button"
        >
          You can&apos;t save the curators at this state.
        </NotificationToast>

        <NotificationToast
          dialog={discardCurationNotificationDialog}
          title="Curation discarded"
          theme="checkmark"
          variant="button"
        >
          We&apos;ll send an email to all curators to let them know about the
          cancellation. <br />
          Admins can now publish the uncurated trial version or start a new
          curation.
        </NotificationToast>

        <NotificationToast
          dialog={discardCurationAndPublishUncuratedDialog}
          title="Curation discarded and trial published"
          theme="checkmark"
          variant="button"
        >
          We&apos;ll send an email to all curators to let them know about the
          cancellation. <br /> The trial has been published uncurated.
        </NotificationToast>
        <div className={styles.actions}>
          {edit ||
            (isPublishable !== null && (
              <>
                <Form className={styles.formContainer} {...form}>
                  <div className={styles.subContainer}>
                    {curationStarted ? (
                      <CuratorModal
                        allCurators={allCurators}
                        curator1={curator1}
                        curator2={curator2}
                        curatorOneApproved={curator1_approved}
                        curatorTwoApproved={curator2_approved}
                        getCuratorNameById={getCuratorNameById}
                        briefTitle={brief_title}
                        nctId={nct_id}
                        getTrialData={getTrialData}
                        setTrialData={setTrialData}
                        permissions={permissions}
                        setPermissions={setPermissions}
                        curatorFailedDialog={curatorFailedDialog}
                      />
                    ) : null}
                    {isSavable ? (
                      <PrivateElement scope="save trial">
                        <Button
                          className={classNames(styles.actionCancelLink, {
                            [styles.hideButtons]: !isFormTrialChanged,
                          })}
                          theme="link"
                          onClick={() => {
                            resetFormValues(formattedTrial);
                            handleTrialChange();
                          }}
                        >
                          Cancel
                        </Button>
                      </PrivateElement>
                    ) : null}

                    {!isFormTrialChanged || !permissions?.['save trial'] ? (
                      // if user cannot save, preview is always displayed
                      <PrivateElement scope="preview trial">
                        <Link
                          asType="external"
                          type="ui"
                          size="75"
                          target="_blank"
                          theme="primary"
                          className={styles.actionLink}
                          to={`/preview/?previewId=${nct_id}`}
                        >
                          Preview
                        </Link>
                      </PrivateElement>
                    ) : null}
                    {curationStarted && (
                      <div>
                        <DialogDisclosure
                          className={styles.discardDialogDisclosure}
                          {...discardCurationPopoverDialog}
                        >
                          <PrivateElement scope="discard curation">
                            <Link
                              asType="external"
                              type="ui"
                              size="75"
                              target="_blank"
                              theme="primary"
                            >
                              Discard curation
                            </Link>
                          </PrivateElement>
                        </DialogDisclosure>

                        <Modal
                          theme="secondary"
                          header="Discard Curation"
                          dialog={discardCurationPopoverDialog}
                          className={styles.modal}
                        >
                          <Content className={styles.modalContentWrapper}>
                            Are you sure you want to discard the curation?
                          </Content>

                          <Content
                            className={styles.modalContentWrapper}
                            variant="bold"
                          >
                            The versions of curation are going to be deleted.
                          </Content>

                          <InputField
                            className={classNames(styles.modalInputField, {
                              [styles.modalInputFieldError]: showErrorMessage,
                            })}
                            name="discard"
                            label="Type DISCARD to confirm"
                            {...form}
                            noMessage
                          />
                          {showErrorMessage && (
                            <div className={styles.errorMessageContainer}>
                              <IconWrapper
                                Icon={IconComponents.SmallWarningCircle}
                                className={styles.smallWarningCircle}
                              />
                              <Content className={styles.formErrorMessage}>
                                Please enter the correct keyword.
                              </Content>
                            </div>
                          )}

                          <div className={styles.modalButtonContainer}>
                            <Button
                              theme="link"
                              size="small"
                              className={styles.underLinedModalLink}
                              onClick={handleModalCancel}
                            >
                              I changed my mind
                            </Button>
                            <Button
                              as={Button}
                              theme="secondary"
                              className={styles.modalSecondaryButton}
                              onClick={() => handleDiscardAndPublishUncurated()}
                              size="small"
                            >
                              Discard and publish uncurated
                            </Button>
                            <Button
                              as={Button}
                              theme="primary"
                              className={styles.modalButton}
                              onClick={() => handleDiscardOnly()}
                              size="small"
                            >
                              Discard only
                            </Button>
                          </div>
                        </Modal>
                      </div>
                    )}

                    {isForcePublishable && (
                      <PrivateElement scope="force publish trial">
                        <Button
                          theme="link"
                          size="default"
                          onClick={() => handlePublishSubmit()}
                          className={styles.underLineLink}
                        >
                          Publish uncurated
                        </Button>
                      </PrivateElement>
                    )}
                  </div>
                  {!isFormTrialChanged && (
                    <PrivateElement scope="approve curation">
                      <Button
                        className={styles.primaryButton}
                        theme="primary"
                        size="default"
                        onClick={() => handleApprove()}
                      >
                        Approve
                      </Button>
                    </PrivateElement>
                  )}
                  {isSavable && (
                    <PrivateElement scope="save trial">
                      <FormSubmitButton
                        className={styles.primaryButton}
                        as={Button}
                        theme="primary"
                        size="default"
                        {...form}
                      >
                        Save changes
                      </FormSubmitButton>
                    </PrivateElement>
                  )}
                  {isCurationStartable && (
                    <PrivateElement scope="start curation">
                      <Button
                        className={styles.primaryButton}
                        theme="primary"
                        size="default"
                        onClick={() => handleStartCuration()}
                      >
                        Start curation
                      </Button>
                    </PrivateElement>
                  )}
                  {isPublishable && (
                    <PrivateElement scope="publish trial">
                      <Button
                        className={styles.publishButton}
                        theme="primary"
                        size="default"
                        onClick={() => handlePublishSubmit()}
                      >
                        Publish
                      </Button>
                    </PrivateElement>
                  )}
                </Form>
              </>
            ))}
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <Heading size="h2" theme="primary">
            {brief_title}
          </Heading>
        </div>
        <div className={styles.actionsContainer}>
          <div className={styles.info}>
            {renderInfoKeyValue('NCT ID', nct_id, true, true)}
            {renderInfoKeyValue(
              'Imported',
              isLoading ? 'loading' : getDateInfo(importedDate),
              false,
              true,
            )}
            {renderInfoKeyValue(
              'Updated',
              isLoading ? 'loading' : getDateInfo(updatedDate),
              true,
              updatedDate && true,
            )}
            {renderInfoKeyValue(
              'Modified',
              isLoading ? 'loading' : getDateInfo(modifiedDate),
              false,
              modifiedDate && true,
            )}
            {renderInfoKeyValue(
              'Published',
              isLoading ? 'loading' : getDateInfo(publishedDate),
              false,
              publishedDate && true,
            )}
            {renderInfoKeyValue(
              'Curation status',
              curation_status,
              false,
              true,
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
