import ExpandableGroup from 'components/admin/trialEdit/ExpandableGroup/ExpandableGroup';
import React, { useEffect } from 'react';

import { TabPanel } from 'reakit/Tab';
import FormRadioGroup from '@moonshineragency/ui/src/components/InputField/components/FormRadioGroup/FormRadioGroup';
import Textarea from '@moonshineragency/ui/src/components/Textarea/Textarea';

const mapToParentCategory = intervention => {
  switch (intervention.parent_id) {
    case 1:
      return 'Biological/Cell based';
    case 2:
      return 'Drug';
    case 3:
      return 'Rehabilitation';
    case 4:
      return 'Surgery';
    case 5:
      return 'Technology';
    default:
      return 'Psychosocial/other';
  }
};

const TrialType = ({ interventionsData, form, tab, onChange }) => {
  const checkedItems = form.values.interventions;

  const checkableOptions = interventionsData.filter(item => item.parent_id);

  const optionsChecked = checkableOptions.filter(item =>
    checkedItems.includes(item.id),
  );

  const getCheckedParentIds = () => {
    const parentIds = optionsChecked.map(item => item.parent_id);

    const uniqueParentIds = parentIds.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    return uniqueParentIds;
  };

  useEffect(() => {
    const checkedParentIds = getCheckedParentIds();

    const isPrimaryInterventionInParentId = checkedParentIds.includes(
      form.values.primary_intervention,
    );

    if (!isPrimaryInterventionInParentId) {
      form.update('primary_intervention', null);
    }

    if (checkedParentIds.length === 1) {
      form.update('primary_intervention', checkedParentIds[0]);
    }
  }, [checkedItems]);

  const filteredInterventionsData = interventionsData.filter(
    iD => iD.parent_id,
  );

  const reducedInterventionsData = filteredInterventionsData.reduce(
    (res, curr) => {
      const parentCategory = mapToParentCategory(curr);
      if (!res[parentCategory]) {
        res[parentCategory] = [{ ...curr }];
      } else {
        res[parentCategory].push(curr);
      }
      return res;
    },
    {},
  );

  const getParentIdForKey = key => {
    const expandableOptions = reducedInterventionsData[key];
    const parentId = expandableOptions[0].parent_id ?? null;

    return parentId;
  };

  return (
    <TabPanel {...tab}>
      <div>
        <FormRadioGroup
          name="study_type"
          title="Type"
          items={[
            {
              label: 'Observational',
              value: 'observational',
            },
            {
              label: 'Interventional',
              value: 'interventional',
            },
          ]}
          {...form}
        />
        <FormRadioGroup
          name="allocation"
          title="Allocation"
          items={[
            {
              label: 'Randomized',
              value: 'Randomized',
            },
            {
              label: 'Non-randomized',
              value: 'Non-Randomized',
            },
            {
              label: 'Not available',
              value: 'N/A',
            },
          ]}
          {...form}
        />
        <Textarea
          {...form}
          name="detailed_description"
          rows="10"
          label="What’s involved"
        />
        {Object.keys(reducedInterventionsData).map(key => {
          const interventionGroup = reducedInterventionsData[key];
          const parentId = getParentIdForKey(key);
          const parentIdFromForm = form.values.primary_intervention;

          return (
            <ExpandableGroup
              key={key}
              form={form}
              parentId={parentId}
              parentIdFromForm={parentIdFromForm}
              getParentIdForKey={getParentIdForKey}
              title={key}
              primaryTitle="Primary intervention"
              name="interventions"
              primaryFieldName="primary_intervention"
              items={interventionGroup}
              checkedItemsFromForm={checkedItems}
              onChange={onChange}
            />
          );
        })}
      </div>
    </TabPanel>
  );
};
export { TrialType as default };
