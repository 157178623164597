import styles from './CuratorModal.module.scss';
import headerStyles from 'components/admin/trialEdit/Header/Header.module.scss';
import Client from 'api/client';
import DataView from 'components/DataView/DataView';
import DropDown from 'components/DropDown/DropDown';
import Selector from 'components/Selector/Selector';
import PrivateElement from 'pages/admin/components/PrivateElement';
import { useDialogState, DialogDisclosure } from 'reakit/Dialog';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import Modal from '@moonshineragency/ui/src/components/Modal/Modal';
import React, { useState, useEffect } from 'react';
import {
  unstable_FormSubmitButton as FormSubmitButton,
  unstable_useFormState as useFormState,
  unstable_FormMessage as FormMessage,
} from 'reakit/Form';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import { usePopoverState } from 'reakit/Popover';
import Form, {
  renderInputErrorFor,
} from '@moonshineragency/ui/src/components/Form/Form';

const CuratorModal = ({
  allCurators,
  curator1,
  curator2,
  curatorOneApproved,
  curatorTwoApproved,
  getCuratorNameById,
  briefTitle,
  nctId,
  getTrialData,
  setTrialData,
  permissions,
  setPermissions,
  curatorFailedDialog,
}) => {
  const dialog = useDialogState();
  const curatorOnePopover = usePopoverState();
  const curatorTwoPopOver = usePopoverState();
  const [isSavedByClick, setIsSavedByClick] = useState(false);
  const [showInitialError, setShowInitialError] = useState(true);

  const curatorForm = useFormState({
    values: {
      curator1: '',
      curator2: '',
    },
    onValidate: values => {
      let errors = {};

      if (!values.curator1) {
        errors = {
          ...errors,
          curator1: renderInputErrorFor('Curator is required'),
        };
      }

      if (!values.curator2) {
        errors = {
          ...errors,
          curator2: renderInputErrorFor('Curator is required'),
        };
      }

      if (Object.keys(errors).length) {
        throw errors;
      }
    },

    onSubmit: async values => {
      const { data } = await Client.admin.trials.curators
        .update(nctId, values)
        .catch(() => {
          curatorFailedDialog.show();
          getTrialData();
        });

      setTrialData({
        trial: data.trial,
        rawTrial: data.rawTrial,
      });

      setPermissions({
        ...permissions,
        ...data.permissions,
      });

      setIsSavedByClick(true);
      dialog.hide();
    },
  });

  const handleReset = () => {
    curatorForm.update('curator1', curator1?.id);
    curatorForm.update('curator2', curator2?.id);
    dialog.hide();
    setShowInitialError(false);
  };
  useEffect(() => {
    if (!dialog.visible && !isSavedByClick) {
      handleReset();
    }
  }, [dialog.visible]);

  useEffect(() => {
    curatorForm.update('curator1', curator1?.id);
    curatorForm.update('curator2', curator2?.id);
  }, [curator1, curator2]);

  useEffect(() => {
    if (curatorForm.values.curator1) {
      curatorOnePopover.hide();
    }
    if (curatorForm.values.curator2) {
      curatorTwoPopOver.hide();
    }
  }, [curatorForm.values]);

  const curatorOneSelectorIcon = () => {
    if (curatorOneApproved) {
      return (
        <IconWrapper
          className={styles.checkmark}
          Icon={IconComponents.SmallCheckBadgeIcon}
        />
      );
    }

    return (
      <IconWrapper
        className={styles.clockIcon}
        Icon={IconComponents.SmallClockSoonIcon}
      />
    );
  };

  const curatorTwoSelectorIcon = () => {
    if (curatorTwoApproved) {
      return (
        <IconWrapper
          className={styles.checkmark}
          Icon={IconComponents.SmallCheckBadgeIcon}
        />
      );
    }

    return (
      <IconWrapper
        className={styles.clockIcon}
        Icon={IconComponents.SmallClockSoonIcon}
      />
    );
  };

  return (
    <>
      <DialogDisclosure
        {...dialog}
        className={headerStyles.selectionContainer}
        tabIndex={-1}
      >
        <PrivateElement scope="assign curators">
          <Selector
            label={getCuratorNameById(curatorForm.values.curator1)}
            shortName="curator1"
            isEmpty={!curatorForm.values.curator1}
            className={headerStyles.selector}
            icon={curatorOneSelectorIcon()}
            placeholder="Assign curator..."
          />
          <Selector
            label={getCuratorNameById(curatorForm.values.curator2)}
            shortName="curator2"
            isEmpty={!curatorForm.values.curator2}
            className={headerStyles.selector}
            icon={curatorTwoSelectorIcon()}
            placeholder="Assign curator..."
          />
        </PrivateElement>
      </DialogDisclosure>

      <Modal
        theme="secondary"
        header="Assign Curators"
        dialog={dialog}
        className={styles.modal}
      >
        <Content className={styles.modalContentWrapper}>
          Please select your two curators for the following trial &quot;
          {briefTitle}&quot;. <br /> The curators will be automatically informed
          about the assignment.
        </Content>
        <Form form={curatorForm}>
          <div className={styles.modalSelectorContainer}>
            <div className={styles.modalSelectorSubContainer}>
              <Selector
                popover={curatorOnePopover}
                label={getCuratorNameById(curatorForm.values.curator1)}
                shortName="curator1"
                isEmpty={!curatorForm.values.curator1}
                className={headerStyles.selector}
                icon={curatorOneSelectorIcon()}
                placeholder="Assign curator..."
                noSpacing
              >
                <DataView
                  popover={curatorOnePopover}
                  name="curator1"
                  className={styles.dataView}
                >
                  <div className={styles.curatorContainer}>
                    <DropDown
                      name="curator1"
                      items={allCurators}
                      form={curatorForm}
                    />
                  </div>
                </DataView>
              </Selector>
              {showInitialError && (
                <FormMessage
                  {...curatorForm}
                  name="curator1"
                  className={styles.errorMessage}
                />
              )}
            </div>
            <div className={styles.modalSelectorSubContainer}>
              <Selector
                popover={curatorTwoPopOver}
                label={getCuratorNameById(curatorForm.values.curator2)}
                shortName="curator2"
                isEmpty={!curatorForm.values.curator2}
                className={headerStyles.selector}
                icon={curatorTwoSelectorIcon()}
                placeholder="Assign curator..."
                noSpacing
              >
                <DataView
                  popover={curatorTwoPopOver}
                  name="curator2"
                  className={styles.dataView}
                >
                  <div className={styles.curatorContainer}>
                    <DropDown
                      name="curator2"
                      items={allCurators}
                      form={curatorForm}
                    />
                  </div>
                </DataView>
              </Selector>
              {showInitialError && (
                <FormMessage
                  {...curatorForm}
                  name="curator2"
                  className={styles.errorMessage}
                />
              )}
            </div>
          </div>
          <div className={styles.modalButtonContainer}>
            <Button theme="link" size="small" onClick={handleReset}>
              Cancel
            </Button>

            <FormSubmitButton
              as={Button}
              theme="primary"
              className={styles.modalButton}
              {...curatorForm}
              size="small"
              onClick={() => setShowInitialError(true)}
            >
              Save changes
            </FormSubmitButton>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default CuratorModal;
