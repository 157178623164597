import styles from 'sections/admin/trialEdit/TrialEditSections.module.scss';
import React, { useEffect, useMemo, useState } from 'react';
import InputField from '@moonshineragency/ui/src/components/InputField/InputField';
import Select from '@moonshineragency/ui/src/components/Select/Select';
import { TabPanel } from 'reakit/Tab';

const OutcomeMeasures = ({ measuresData, form, tab, onChange }) => {
  const [optionValues, setOptionValues] = useState([]);

  const outcomeMeasures = measuresData.map(
    item =>
      ({
        label: item.name,
        value: item.id,
      } ?? []),
  );

  const outcomeMeasureOptions = useMemo(() => {
    return outcomeMeasures;
  }, [measuresData]);

  const selectedOutcomes = outcomeMeasures.filter(item => {
    const formOutcomeMeasures = form.values?.outcome_measures || [];
    const isItemIncluded = formOutcomeMeasures.includes(item.value);

    return isItemIncluded;
  });

  useEffect(() => {
    if (form.values.outcome_measures.length) {
      setOptionValues(selectedOutcomes);
    }
  }, [form.values]);

  const handleOptionValuesChange = (options, actionInfo) => {
    const { action } = actionInfo;
    const option = actionInfo.option ?? null;
    const removedValue = actionInfo.removedValue ?? null;

    if (action === 'remove-value') {
      setOptionValues(
        options.filter(stateOption => stateOption.value !== removedValue.value),
      );
    }

    if (action === 'select-option') {
      const currentOptionValues = optionValues ?? [];
      currentOptionValues.push(option);
    }

    form.update(
      'outcome_measures',
      options.map(op => op.value),
    );

    const values = options.map(op => op.value);

    if (values === []) {
      setOptionValues(null);
    }

    setOptionValues(options);
    onChange();
  };

  const customStyles = {
    multiValue: provided => ({
      ...provided,
      borderRadius: 4,
      color: '#879199',
      backgroundColor: '#EBF0F5',
      cursor: 'pointer',
      'text-overflow': 'none',
      'white-space': 'unset',
      'max-width': '30rem',

      '&:hover': {
        backgroundColor: '#EBF0F5',
      },
    }),
    valueContainer: provided => ({
      ...provided,
      cursor: 'pointer',
    }),
    multiValueRemove: provided => ({
      ...provided,
      ':hover': {
        backgroundColor: '#EBF0F5',
        color: '#41474D',
      },
    }),
  };

  return (
    <TabPanel {...tab}>
      <div>
        <Select
          name="outcome_measuers"
          label="Outcome measures"
          placeholder="Outcome measures"
          options={outcomeMeasureOptions}
          form={form}
          isFixedValue
          onChange={handleOptionValuesChange}
          value={optionValues}
          styles={customStyles}
        />
        <InputField
          label="Final testing will be performed after"
          name="final_testing_performed"
          {...form}
          className={styles.inputFieldFinalTesting}
        />
      </div>
    </TabPanel>
  );
};

export { OutcomeMeasures as default };
