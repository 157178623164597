import s from './Select.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import {
  unstable_FormLabel as FormLabel,
  unstable_Form as ReaKitForm,
} from 'reakit/Form';
import classNames from 'classnames';
import CreatableSelect from 'react-select/creatable';
import FixedSelect from 'react-select';

const Select = ({
  name,
  labelHidden,
  label,
  variant,
  className,
  form,
  isFixedValue,
  options = [],
  value,
  onChange,
  onValueChange,
  placeholder,
  components,
  styles,
}) => {
  return (
    <div className={classNames(s.container, className)}>
      <FormLabel
        className={
          labelHidden
            ? classNames(s.label, s.hidden, s[variant])
            : classNames(s.label, s[variant], {
                [s.hasError]: form.errors[name] && form.touched[name],
              })
        }
        {...form}
        name={name}
      >
        {label}
      </FormLabel>

      {isFixedValue ? (
        <FixedSelect
          value={value}
          options={options}
          onChange={(optionsArg, actionInfo) =>
            onChange(optionsArg, actionInfo)
          }
          isMulti
          isClearable
          onValueChange={() => onValueChange()}
          styles={styles}
        />
      ) : (
        <CreatableSelect
          onChange={(newValue = []) => {
            form.update(
              name,
              newValue.map(item => item.value),
            );
            onValueChange();
          }}
          isMulti
          placeholder={placeholder}
          value={options.filter(item => form.values[name].includes(item.value))}
          options={options}
          components={components}
          styles={styles}
        />
      )}
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  labelHidden: PropTypes.bool,
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
  form: PropTypes.objectOf(ReaKitForm).isRequired,
  options: PropTypes.arrayOf().isRequired,
  isFixedValue: PropTypes.bool,
  value: PropTypes.arrayOf(),
  onChange: PropTypes.func,
  onValueChange: PropTypes.func.isRequired,
};

Select.defaultProps = {
  labelHidden: false,
  variant: '',
  isFixedValue: false,
  value: null,
  onChange: () => null,
};

export default Select;
