import styles from './CheckboxMockup.module.scss';
import React from 'react';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import classNames from 'classnames';

const CheckboxMockup = ({ name, checked, changedNew, changedOld }) => {
  return (
    <div
      className={classNames(styles.checkboxHighlight, {
        [styles.changedNew]: changedNew,
        [styles.changedOld]: changedOld,
      })}
    >
      <div
        className={classNames(styles.checkbox, {
          [styles.checkboxChecked]: checked,
        })}
      >
        {checked && <IconWrapper Icon={IconComponents.SmallCheckIcon} />}
      </div>
      <span>{name}</span>
    </div>
  );
};

export default CheckboxMockup;
