import styles from 'sections/admin/trialEdit/TrialEditSections.module.scss';
import TrialCompare from 'components/admin/trialEdit/TrialCompare/TrialCompare';
import React from 'react';
import { getDate, getYear, getHours, getMinutes, format } from 'date-fns';
import { Separator } from 'reakit/Separator';
import Link from '@moonshineragency/ui/src/components/Link/Link';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';

const TrialHistory = ({
  trialHistoryData,
  nctId,
  trial,
  injuriesData,
  interventionsData,
  benefitsData,
  mechanismsData,
  versions,
  trialCommentsData,
  trialForm,
}) => {
  return (
    <>
      {trialHistoryData?.history?.map(history => {
        const time = new Date(history.created_at);
        const trialEditDate = {
          day: getDate(time),
          month: format(time, 'LLLL'),
          year: getYear(time),
          hour: getHours(time),
          minutes: (getMinutes(time) < 10 ? '0' : '') + getMinutes(time),
        };

        const renderUpperCase = item =>
          item !== null &&
          item.charAt(0).toUpperCase() + item.slice(1).replace(/_/g, ' ');

        const renderUserAction = userTrial => {
          /* with active/passive users, (User imported/updated/publisched trial, started/approved/discarded curation, assigned curator ...) */
          if (userTrial?.active_user_id || userTrial?.passive_user_id) {
            return (
              <Content variant="light" theme="regular" size="uiText75">
                {userTrial.active_user_id?.first_name}{' '}
                {userTrial.active_user_id?.last_name}
                <span className={styles.text}>
                  {' '}
                  {userTrial.action_type}
                </span>{' '}
                {!userTrial?.action_type?.includes('assigned')
                  ? renderUpperCase(userTrial.change_on)
                  : `${userTrial.passive_user_id?.first_name}
                  ${userTrial.passive_user_id?.last_name}`}
              </Content>
            );
          }
          /* without active/passive users,(trial imported/updated ...) */
          return (
            <Content variant="light" theme="regular" size="uiText75">
              {renderUpperCase(userTrial.change_on)}
              <span className={styles.text}> {userTrial.action_type}</span>
            </Content>
          );
        };

        const renderModified = userTrial => {
          return (
            <Content variant="light" theme="regular" size="uiText75">
              {userTrial.active_user_id?.first_name}{' '}
              {userTrial.active_user_id?.last_name}
              <span className={styles.text}> {userTrial.action_type}</span>{' '}
              {userTrial.modified_tabs
                ? userTrial.modified_tabs?.map((item, index) => {
                    return (
                      <span key={item}>{`${index ? ',' : ''}  ${renderUpperCase(
                        item,
                      )}`}</span>
                    );
                  })
                : 'Trial'}
            </Content>
          );
        };

        return (
          <div key={history.id} className={styles.historyWrapper}>
            <Content variant="light" theme="secondary" size="uiText50">
              {trialEditDate.day || ''} {trialEditDate.month || ''}{' '}
              {trialEditDate.year || ''}, {trialEditDate.hour || ''}:
              {trialEditDate.minutes || ''}
            </Content>
            {history?.action_type !== 'modified' && renderUserAction(history)}
            {history?.action_type === 'modified' && renderModified(history)}
            {/* show all link appears only with "updated" action */}
            {history.action_type.includes('updated') && (
              <Link
                asType="external"
                variant="noUnderline"
                to={`https://clinicaltrials.gov/ct2/history/${history.nct_id}`}
                target="_blank"
                className={styles.link}
              >
                Show all updates
              </Link>
            )}
            {/* compare link appears only with "modified" action */}
            {history.action_type.includes('modified') && (
              <div>
                <Content
                  variant="light"
                  theme="regular"
                  size="uiText75"
                  className={styles.versionWrapper}
                >
                  Version {history.old_version}
                  <IconWrapper
                    className={styles.arrowForwardIcon}
                    Icon={IconComponents.ArrowForwardIcon}
                  />
                  Version {history.new_version}
                  <TrialCompare
                    // eslint-disable-next-line camelcase
                    nctId={nctId}
                    trial={trial}
                    injuriesData={injuriesData}
                    interventionsData={interventionsData}
                    benefitsData={benefitsData}
                    mechanismsData={mechanismsData}
                    versions={versions}
                    versionToCompare={history?.new_version}
                    versionToCompareAgainst={history?.old_version}
                    trialCommentsData={trialCommentsData}
                    trialForm={trialForm}
                  />
                </Content>
              </div>
            )}

            <div className={styles.separator}>
              <Separator />
            </div>
          </div>
        );
      })}
    </>
  );
};
export default TrialHistory;
