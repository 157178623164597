import ExpandableGroup from 'components/admin/trialEdit/ExpandableGroup/ExpandableGroup';
import React, { useEffect } from 'react';
import { TabPanel } from 'reakit/Tab';
import FormCheckboxGroup from '@moonshineragency/ui/src/components/InputField/components/FormCheckboxGroup/FormCheckboxGroup';
import { Separator } from 'reakit/Separator';

const mapToParentCategory = benefit => {
  switch (benefit.parent_id) {
    case 1:
      return 'Arm/hand function';
    case 2:
      return 'Bladder health/function';
    case 3:
      return 'Bone health';
    case 4:
      return 'Bowel health';
    case 5:
      return 'General health';
    case 6:
      return 'Mental health and psychosocial factors';
    case 7:
      return 'Pain';
    case 8:
      return 'Sensory function';
    case 9:
      return 'Sexual health';
    case 10:
      return 'Skin health';
    case 11:
      return 'Spasticity';
    case 12:
      return 'Standing/walking/mobility';
    case 90:
      return 'Mechanism of action';
    default:
      return 'General health';
  }
};

const PotentialBenefits = ({
  form,
  tab,
  benefitsData,
  mechanisms,
  onChange,
}) => {
  const checkedItems = form.values.benefits;

  const reducedBenefitsData = benefitsData
    .filter(iD => iD.parent_id)
    .reduce((res, curr) => {
      const parentCategory = mapToParentCategory(curr);
      if (!res[parentCategory]) {
        res[parentCategory] = [{ ...curr }];
      } else {
        res[parentCategory].push(curr);
      }
      return res;
    }, {});

  const mechanismsValues = mechanisms.map(item => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  const getParentIdForKey = key => {
    const expandableOptions = reducedBenefitsData[key];
    const parentId = expandableOptions[0].parent_id ?? null;

    return parentId;
  };

  const checkableOptions = benefitsData.filter(item => item.parent_id);

  const optionsChecked = checkableOptions.filter(item =>
    checkedItems.includes(item.id),
  );

  const getCheckedParentIds = () => {
    const parentIds = optionsChecked.map(item => item.parent_id);

    const uniqueParentIds = parentIds.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    return uniqueParentIds;
  };

  useEffect(() => {
    const checkedParentIds = getCheckedParentIds();

    const isPrimaryBenefitInParentId = checkedParentIds.includes(
      form.values.primary_benefit,
    );

    if (!isPrimaryBenefitInParentId) {
      form.update('primary_benefit', null);
    }

    if (checkedParentIds.length === 1) {
      form.update('primary_benefit', checkedParentIds[0]);
    }
  }, [checkedItems]);

  return (
    <TabPanel {...tab}>
      <div />
      {Object.keys(reducedBenefitsData).map(key => {
        const benefitGroup = reducedBenefitsData[key];
        const parentId = getParentIdForKey(key);
        const parentIdFromForm = form.values.primary_benefit;

        return (
          <ExpandableGroup
            key={key}
            form={form}
            parentId={parentId}
            parentIdFromForm={parentIdFromForm}
            getParentIdForKey={getParentIdForKey}
            title={key}
            primaryTitle="Primary benefit"
            name="benefits"
            primaryFieldName="primary_benefit"
            items={benefitGroup}
            checkedItemsFromForm={checkedItems}
            onChange={onChange}
          />
        );
      })}
      <Separator />

      <FormCheckboxGroup
        name="recovery_mechanisms"
        title="Mechanism of recovery"
        items={mechanismsValues}
        {...form}
      />
    </TabPanel>
  );
};

export { PotentialBenefits as default };
