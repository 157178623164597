/* eslint-disable no-alert */
import styles from './trialEdit.module.scss';
import Container from 'components/Container/Container';
import Layout from 'components/Layout/Layout';
import Header from 'components/admin/trialEdit/Header/Header';
import Forms from 'components/admin/trialEdit/Forms/Forms';
import Client from 'api/client';
import { AuthorizationContext } from 'context';
import { useParams } from '@reach/router';
import React, { useState, useEffect, useContext } from 'react';
import { unstable_useFormState as useFormState } from 'reakit/Form';
import { useDialogState } from 'reakit/Dialog';

/**
 * Fetches trial data to edit and sets its result to the trialEditData state variable.
 * @param {function} setTrialEditData
 * @returns {Promise}
 */
const getTrialEditData = async setTrialEditData => {
  const { data: benefitsData } = await Client.benefits.get();
  const { data: interventionsData } = await Client.interventions.get();
  const { data: measuresData } = await Client.measures.get();
  const { data: injuriesData } = await Client.injuries.get();
  const { data: mechanismsData } = await Client.mechanisms.get();

  setTrialEditData({
    benefitsData,
    interventionsData,
    measuresData,
    injuriesData,
    mechanismsData,
  });
};

/**
 * Fetches trial data.
 * @param {string} nctId
 * @param {function} setTrialData
 * @returns {Promise}
 */
const getTrialData = async (
  nctId,
  setTrialData,
  setPermissions,
  permissions,
) => {
  const { data } = await Client.trialAdmin.get(nctId);

  setPermissions({
    ...permissions,
    ...data.permissions,
  });

  setTrialData({
    trial: data.trial,
    rawTrial: data.rawTrial,
  });
};
const getTrialHistoryData = async (nctId, setTrialHistoryData) => {
  const { data } = await Client.trialHistory.get(nctId);

  setTrialHistoryData({
    history: data,
  });
};

const getTrialCommentsData = async (nctId, setTrialCommentsData) => {
  const { data } = await Client.trialComments.get(nctId);

  setTrialCommentsData({
    comments: data,
  });
};

const TrialEdit = () => {
  // eslint-disable-next-line camelcase
  const { nct_id } = useParams();

  const [trialHistoryData, setTrialHistoryData] = useState();
  const [trialCommentsData, setTrialCommentsData] = useState([]);
  const [trialEditData, setTrialEditData] = useState();
  const [trialData, setTrialData] = useState();
  const [isFormTrialChanged, setIsFormTrialChanged] = useState(false);
  const [isPublishable, setIsPublishable] = useState(null);
  const [isSavable, setIsSavable] = useState(null);
  const [isCurationStartable, setIsCurationStartable] = useState(null);
  const [curationStarted, setCurationStarted] = useState(null);
  const [isForcePublishable, setIsForcePublishable] = useState(null);
  const [permissions, setPermissions] = useContext(AuthorizationContext);
  const unsaveableDialog = useDialogState();

  useEffect(() => {
    getTrialEditData(setTrialEditData);
    getTrialData(nct_id, setTrialData, setPermissions, permissions);
    getTrialHistoryData(nct_id, setTrialHistoryData);
    getTrialCommentsData(nct_id, setTrialCommentsData);
  }, []);

  window.onchange = e => {
    if (
      e?.target?.name !== 'text' &&
      e?.target?.name !== 'curator1' &&
      e?.target?.name !== 'curator2' &&
      e?.target?.name !== 'email' &&
      e?.target?.name !== 'password' &&
      e?.target?.name !== 'discard'
    ) {
      // do not update isFormTrialChanged if user changed curators or adds a comment
      setIsFormTrialChanged(true);
    }
  };

  const handleChange = () => {
    setIsFormTrialChanged(true);
  };

  const handleTrialChange = () => {
    setIsFormTrialChanged(false);
  };

  const trial = trialData ? trialData.trial : [];
  const rawTrial = trialData ? trialData.rawTrial : [];
  const benefitsData = trialEditData ? trialEditData.benefitsData : [];
  const mechanismsData = trialEditData ? trialEditData.mechanismsData : [];

  const interventionsData = trialEditData
    ? trialEditData.interventionsData
    : [];
  const measuresData = trialEditData ? trialEditData.measuresData : [];
  const injuriesData = trialEditData ? trialEditData.injuriesData : [];

  useEffect(() => {
    const checkIsPublishedAlready = !!trial.published_at;

    const checkIsPublishable =
      (trial.curator1_approved && trial.curator2_approved) ||
      checkIsPublishedAlready;

    const checkIsSavable = isFormTrialChanged;

    const checkIsCurationStartable =
      !isFormTrialChanged && trial.curation_status === 'uncurated';

    const checkHasCurationStarted = trial.curation_status === 'started';

    const checkIsCurrentTrialVersionPublished =
      trial.is_version_published === undefined
        ? true
        : trial.is_version_published;

    const checkIsForcePublishable =
      !checkIsCurrentTrialVersionPublished &&
      !isFormTrialChanged &&
      !checkHasCurationStarted &&
      !checkIsPublishedAlready &&
      !trial.is_version_published;

    setIsPublishable(checkIsPublishable);
    setIsSavable(checkIsSavable);
    setIsCurationStartable(checkIsCurationStartable);
    setCurationStarted(checkHasCurationStarted);
    setIsForcePublishable(checkIsForcePublishable);
  }, [trial, trialData, isFormTrialChanged]);

  // const rawTrial = [];
  const rawTrialData = rawTrial;
  const mapTrialDataForForm = unmappedTrial => {
    return {
      ...unmappedTrial,
      ais: [
        unmappedTrial.ais_a ? 'ais_a' : null,
        unmappedTrial.ais_b ? 'ais_b' : null,
        unmappedTrial.ais_c ? 'ais_c' : null,
        unmappedTrial.ais_d ? 'ais_d' : null,
        unmappedTrial.ais_e ? 'ais_e' : null,
      ],
      interventions: unmappedTrial.interventions
        ? unmappedTrial.interventions.map(intervention => intervention.id)
        : [],
      benefits: unmappedTrial.benefits
        ? unmappedTrial.benefits.map(benefit => benefit.id)
        : [],
      primary_benefit: unmappedTrial.primary_benefit?.id,
      primary_intervention: unmappedTrial.primary_intervention?.id,
      injuries: unmappedTrial.injuries
        ? unmappedTrial.injuries.map(injury => injury.id)
        : [],
      recovery_mechanisms: unmappedTrial.recovery_mechanisms
        ? unmappedTrial.recovery_mechanisms.map(item => item.id)
        : [],
      outcome_measures: trial.outcome_measures
        ? trial.outcome_measures.map(item => item.id)
        : [],
    };
  };

  const formattedTrial = mapTrialDataForForm({ ...trial });

  const formTrial = useFormState({
    values: {
      ...formattedTrial,
    },
    validateOnChange: false,
    onSubmit: async values => {
      const mappedValues = {
        ...values,
        ais_a: values.ais.includes('ais_a'),
        ais_b: values.ais.includes('ais_b'),
        ais_c: values.ais.includes('ais_c'),
        ais_d: values.ais.includes('ais_d'),
        ais_e: values.ais.includes('ais_e'),
      };

      if (isSavable) {
        let actionBarSaved = isSavable;

        const resp = await Client.admin.trials
          .save(values.nct_id, mappedValues)
          .catch(() => {
            unsaveableDialog.show();
          });

        if (resp.status === 201) {
          await getTrialData(nct_id, setTrialData, setPermissions, permissions);
          actionBarSaved = false;
          getTrialHistoryData(nct_id, setTrialHistoryData);
        }

        setIsFormTrialChanged(actionBarSaved);
      }
    },
  });

  const rawDataForm = useFormState({
    values: {
      ...rawTrialData,
    },
  });

  useEffect(() => {
    Object.keys(rawTrialData).forEach(key => {
      rawDataForm.update(key, rawTrialData[key]);
    });

    Object.keys(formattedTrial).forEach(key => {
      formTrial.update(key, formattedTrial[key]);
    });
  }, [trialData, trialEditData]);

  return (
    // eslint-disable-next-line camelcase
    <Layout title={nct_id} isAdmin>
      <Container size="wide">
        <div className={styles.grid}>
          <Header
            trial={trial}
            form={formTrial}
            rawTrial={rawTrial}
            setTrialData={setTrialData}
            permissions={permissions}
            setPermissions={setPermissions}
            isPublishable={isPublishable}
            isCurationStartable={isCurationStartable}
            isSavable={isSavable}
            curationStarted={curationStarted}
            formattedTrial={formattedTrial}
            handleTrialChange={handleTrialChange}
            isFormTrialChanged={isFormTrialChanged}
            isForcePublishable={isForcePublishable}
            getTrialData={getTrialData}
            setIsFormTrialChanged={setIsFormTrialChanged}
            unsaveableDialog={unsaveableDialog}
            trialHistoryData={trialHistoryData}
          />
          <Forms
            // eslint-disable-next-line camelcase
            nctId={nct_id}
            benefitsData={benefitsData}
            interventionsData={interventionsData}
            measuresData={measuresData}
            injuriesData={injuriesData}
            trial={trial}
            trialForm={formTrial}
            rawDataForm={rawDataForm}
            mechanisms={mechanismsData}
            onChange={handleChange}
            trialHistoryData={trialHistoryData}
            trialCommentsData={trialCommentsData}
            versions={formattedTrial.versions}
          />
        </div>
      </Container>
    </Layout>
  );
};

export default TrialEdit;
