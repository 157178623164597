import styles from './PotentialBenefits.module.scss';
import CheckboxMockup from 'components/admin/trialEdit/TrialCompare/components/CheckboxMockup/CheckboxMockup';
import VersionComparisonSelector from 'components/admin/trialEdit/TrialCompare/components/VersionComparisonSelector/VersionComparisonSelector';
import React from 'react';
import classNames from 'classnames';
import { TabPanel } from 'reakit/Tab';

const mapToParentCategory = benefit => {
  switch (benefit.parent_id) {
    case 1:
      return 'Arm/hand function';
    case 2:
      return 'Bladder health/function';
    case 3:
      return 'Bone health';
    case 4:
      return 'Bowel health';
    case 5:
      return 'General health';
    case 6:
      return 'Mental health and psychosocial factors';
    case 7:
      return 'Pain';
    case 8:
      return 'Sensory function';
    case 9:
      return 'Sexual health';
    case 10:
      return 'Skin health';
    case 11:
      return 'Spasticity';
    case 12:
      return 'Standing/walking/mobility';
    case 90:
      return 'Mechanism of action';
    default:
      return 'General health';
  }
};

const PotentialBenefits = ({
  trialOlderVersion,
  trialNewerVersion,
  tab,
  benefitsData,
  mechanismsData,
  olderVersionNumber,
  newerVersionNumber,
  setOlderVersionNumber,
  setNewerVersionNumber,
}) => {
  const olderPrimaryBenefitId = trialOlderVersion?.primary_benefit?.id;
  const newerPrimaryBenefitId = trialNewerVersion?.primary_benefit?.id;

  const newerTrialCheckedBenefits = trialNewerVersion?.benefits
    ? trialNewerVersion.benefits.map(benefit => benefit.id)
    : [];

  const olderTrialCheckedBenefits = trialOlderVersion?.benefits
    ? trialOlderVersion.benefits.map(benefit => benefit.id)
    : [];

  const reducedBenefitsData = benefitsData
    .filter(iD => iD.parent_id)
    .reduce((res, curr) => {
      const parentCategory = mapToParentCategory(curr);
      if (!res[parentCategory]) {
        res[parentCategory] = [{ ...curr }];
      } else {
        res[parentCategory].push(curr);
      }
      return res;
    }, {});

  const olderChosenMechanisms = trialOlderVersion.recovery_mechanisms
    ? trialOlderVersion.recovery_mechanisms.map(item => item.id)
    : [];

  const newerChosenMechanisms = trialNewerVersion.recovery_mechanisms
    ? trialNewerVersion.recovery_mechanisms.map(item => item.id)
    : [];

  const mechanismsValues = mechanismsData.map(item => {
    return {
      name: item.name,
      id: item.id,
    };
  });

  return (
    <TabPanel {...tab}>
      <VersionComparisonSelector
        olderVersionNumber={olderVersionNumber}
        newerVersionNumber={newerVersionNumber}
        setOlderVersionNumber={setOlderVersionNumber}
        setNewerVersionNumber={setNewerVersionNumber}
        olderVersionModifiedAt={trialOlderVersion.modified_at}
        newerVersionModifiedAt={trialNewerVersion.modified_at}
        versions={trialNewerVersion.versions}
      />
      <div className={styles.paneWrapper}>
        <div className={styles.divider} />
        <div className={styles.divider} />
        {Object.keys(reducedBenefitsData).map(key => {
          const benefitGroup = reducedBenefitsData[key];
          const benefitGroupParentId = benefitGroup[0]?.parent_id;

          return (
            <>
              <div>
                <div className={styles.paneTitle}>
                  {key}{' '}
                  {benefitGroupParentId === olderPrimaryBenefitId && (
                    <span
                      className={classNames(styles.primaryBenefit, {
                        [styles.primaryChangedOld]:
                          olderPrimaryBenefitId !== newerPrimaryBenefitId,
                      })}
                    >
                      Primary Benefit
                    </span>
                  )}
                </div>
                {benefitGroup.map(benefit => (
                  <CheckboxMockup
                    name={benefit.name}
                    checked={olderTrialCheckedBenefits.includes(benefit.id)}
                    changedOld={
                      olderTrialCheckedBenefits.includes(benefit.id) &&
                      !newerTrialCheckedBenefits.includes(benefit.id)
                    }
                  />
                ))}
              </div>
              <div>
                <div className={styles.paneTitle}>
                  {key}{' '}
                  {benefitGroupParentId === newerPrimaryBenefitId && (
                    <span
                      className={classNames(styles.primaryBenefit, {
                        [styles.primaryChangedNew]:
                          olderPrimaryBenefitId !== newerPrimaryBenefitId,
                      })}
                    >
                      Primary Benefit
                    </span>
                  )}
                </div>
                {benefitGroup.map(benefit => (
                  <CheckboxMockup
                    name={benefit.name}
                    checked={newerTrialCheckedBenefits.includes(benefit.id)}
                    changedNew={
                      newerTrialCheckedBenefits.includes(benefit.id) &&
                      !olderTrialCheckedBenefits.includes(benefit.id)
                    }
                  />
                ))}
              </div>
              <div className={styles.divider} />
              <div className={styles.divider} />
            </>
          );
        })}
        <div>
          <div className={styles.paneTitle}>Mechanism of recovery</div>
          {mechanismsValues.map(mechanism => (
            <CheckboxMockup
              name={mechanism.name}
              checked={olderChosenMechanisms.includes(mechanism.id)}
              changedOld={
                olderChosenMechanisms.includes(mechanism.id) &&
                !newerChosenMechanisms.includes(mechanism.id)
              }
            />
          ))}
        </div>
        <div>
          <div className={styles.paneTitle}>Mechanism of recovery</div>
          {mechanismsValues.map(mechanism => (
            <CheckboxMockup
              name={mechanism.name}
              checked={newerChosenMechanisms.includes(mechanism.id)}
              changedNew={
                newerChosenMechanisms.includes(mechanism.id) &&
                !olderChosenMechanisms.includes(mechanism.id)
              }
            />
          ))}
        </div>
      </div>
    </TabPanel>
  );
};

export { PotentialBenefits as default };
