import styles from './PillMockup.module.scss';
import React from 'react';
import classNames from 'classnames';

const PillMockup = ({ label, changedNew, changedOld, breakPill }) => {
  return (
    <span
      className={classNames(styles.pill, {
        [styles.changedNew]: changedNew,
        [styles.changedOld]: changedOld,
        [styles.breakPill]: breakPill,
      })}
    >
      {label}
    </span>
  );
};

export default PillMockup;
