import styles from 'sections/admin/trialEdit/TrialEditSections.module.scss';
import React from 'react';
import Checkbox from '@moonshineragency/ui/src/components/Checkbox/Checkbox';
import { unstable_FormCheckbox as FormCheckbox } from 'reakit/Form';
import Textarea from '@moonshineragency/ui/src/components/Textarea/Textarea';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import { TabPanel } from 'reakit/Tab';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';

const General = ({ form, tab }) => {
  return (
    <TabPanel {...tab}>
      <div>
        <div className={styles.explanationContainer}>
          <IconWrapper
            Icon={IconComponents.CheckBadgeIcon}
            className={styles.icon}
          />
          <Content className={styles.explanation} size="copy75">
            <span>
              Write the curated &quot;about&quot; section the following way:
            </span>{' '}
            <br />
            This{' '}
            <span className={styles.explanationPlaceholder}>[phase…] </span>
            trial is enrolling{' '}
            <span className={styles.explanationPlaceholder}>
              […] [number of participants]
            </span>{' '}
            with{' '}
            <span className={styles.explanationPlaceholder}>
              […] [time since injury (e.g. chronic)] […] [injury level (e.g.
              cervical]{' '}
            </span>{' '}
            spinal cord injury to test the effect of{' '}
            <span className={styles.explanationPlaceholder}>
              […] [intervention (e.g. drug)]{' '}
            </span>
            on{' '}
            <span className={styles.explanationPlaceholder}>
              […] [primary outcome (e.g. walking function)].{' '}
            </span>
            <br /> If included, participants will{' '}
            <span className={styles.explanationPlaceholder}>[…]</span>.<br />
            Final testing of{' '}
            <span className={styles.explanationPlaceholder}>[…]</span> will be
            performed after{' '}
            <span className={styles.explanationPlaceholder}>
              […] [last follow up timepoint]
            </span>
            .
          </Content>
        </div>
        <Textarea
          {...form}
          name="about_trial"
          rows="15"
          label="About this trial"
        />
        <FormCheckbox
          {...form}
          as={Checkbox}
          name="emsci_trial"
          label="EMSCI discussed trial"
        />
      </div>
    </TabPanel>
  );
};

export { General as default };
