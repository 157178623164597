import styles from './RadioMockup.module.scss';
import React from 'react';
import Radio from '@moonshineragency/ui/src/components/Radio/Radio';
import classNames from 'classnames';

const RadioMockup = ({ name, label, checked, changedNew, changedOld }) => {
  return (
    <span
      className={classNames(styles.highlight, {
        [styles.changedNew]: changedNew,
        [styles.changedOld]: changedOld,
      })}
    >
      <Radio name={name} label={label} checked={checked} disabled />
    </span>
  );
};

export default RadioMockup;
