import styles from './General.module.scss';
import CheckboxMockup from 'components/admin/trialEdit/TrialCompare/components/CheckboxMockup/CheckboxMockup';
import VersionComparisonSelector from 'components/admin/trialEdit/TrialCompare/components/VersionComparisonSelector/VersionComparisonSelector';
import React from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { TabPanel } from 'reakit/Tab';

const General = ({
  trialOlderVersion,
  trialNewerVersion,
  tab,
  olderVersionNumber,
  newerVersionNumber,
  setOlderVersionNumber,
  setNewerVersionNumber,
}) => {
  const diffViewerCustomStyles = {
    marker: {
      display: 'none',
    },
    titleBlock: {
      display: 'none',
    },
    diffAdded: {
      'border-radius': '6px',
      padding: '10px',
    },
    diffRemoved: {
      'border-radius': '6px',
      padding: '10px',
    },
  };

  const isAboutTrialChanged = !(
    JSON.stringify(trialNewerVersion.about_trial) ===
    JSON.stringify(trialOlderVersion.about_trial)
  );

  return (
    <TabPanel {...tab}>
      <div>
        <VersionComparisonSelector
          olderVersionNumber={olderVersionNumber}
          newerVersionNumber={newerVersionNumber}
          setOlderVersionNumber={setOlderVersionNumber}
          setNewerVersionNumber={setNewerVersionNumber}
          olderVersionModifiedAt={trialOlderVersion.modified_at}
          newerVersionModifiedAt={trialNewerVersion.modified_at}
          versions={trialNewerVersion.versions}
        />
      </div>
      <div className={styles.paneWrapper}>
        <div className={styles.divider} />
        <div className={styles.divider} />
      </div>
      <div>
        <div className={styles.diffViewerContent}>
          <div className={styles.paneTitle}>About this trial</div>
          {isAboutTrialChanged ? (
            <ReactDiffViewer
              compareMethod={DiffMethod.WORDS}
              styles={diffViewerCustomStyles}
              hideLineNumbers
              oldValue={trialOlderVersion.about_trial}
              newValue={trialNewerVersion.about_trial}
            />
          ) : (
            <div>{trialNewerVersion.about_trial}</div>
          )}
        </div>
      </div>
      <div className={styles.paneWrapper}>
        <div>
          <div className={styles.paneTitle}>EMSCI discussed trial</div>
          <CheckboxMockup
            name="EMSCI discussed trial"
            checked={trialOlderVersion.emsci_trial}
            changedNew={
              !trialNewerVersion.emsci_trial && trialOlderVersion.emsci_trial
            }
          />
        </div>
        <div>
          <div className={styles.paneTitle}>EMSCI discussed trial</div>
          <CheckboxMockup
            name="EMSCI discussed trial"
            checked={trialNewerVersion.emsci_trial}
            changedOld={
              !trialNewerVersion.emsci_trial && trialOlderVersion.emsci_trial
            }
          />
        </div>
      </div>
    </TabPanel>
  );
};

export { General as default };
