import styles from './OutcomeMeasures.module.scss';
import VersionComparisonSelector from 'components/admin/trialEdit/TrialCompare/components/VersionComparisonSelector/VersionComparisonSelector';
import PillMockup from 'components/admin/trialEdit/TrialCompare/components/PillMockup/PillMockup';
import InputMockup from 'components/admin/trialEdit/TrialCompare/components//InputMockup/InputMockup';
import React from 'react';
import { TabPanel } from 'reakit/Tab';

const OutcomeMeasures = ({
  trialOlderVersion,
  trialNewerVersion,
  tab,
  olderVersionNumber,
  newerVersionNumber,
  setOlderVersionNumber,
  setNewerVersionNumber,
}) => {
  const chosenOlderOutcomeMeasures = trialOlderVersion?.outcome_measures?.map(
    item => item.name,
  );
  const chosenNewerOutcomeMeasures = trialNewerVersion?.outcome_measures?.map(
    item => item.name,
  );

  const olderOutcomeMeasures =
    chosenOlderOutcomeMeasures?.length > 0
      ? chosenOlderOutcomeMeasures
      : ['nothing chosen...'];

  const newerOutcomeMeasures =
    chosenNewerOutcomeMeasures?.length > 0
      ? chosenNewerOutcomeMeasures
      : ['nothing chosen...'];

  return (
    <TabPanel {...tab}>
      <VersionComparisonSelector
        olderVersionNumber={olderVersionNumber}
        newerVersionNumber={newerVersionNumber}
        setOlderVersionNumber={setOlderVersionNumber}
        setNewerVersionNumber={setNewerVersionNumber}
        olderVersionModifiedAt={trialOlderVersion.modified_at}
        newerVersionModifiedAt={trialNewerVersion.modified_at}
        versions={trialNewerVersion.versions}
      />
      <div className={styles.paneWrapper}>
        <div className={styles.divider} />
        <div className={styles.divider} />
        <div>
          <div className={styles.paneTitle}>Outcome measures</div>
          {olderOutcomeMeasures?.map(label => (
            <PillMockup
              label={label}
              changedOld={
                !olderOutcomeMeasures?.includes(label) &&
                newerOutcomeMeasures?.includes(label)
              }
              breakPill
            />
          ))}
        </div>
        <div>
          <div className={styles.paneTitle}>Outcome measures</div>
          {newerOutcomeMeasures?.map(label => (
            <PillMockup
              label={label}
              changedNew={
                !olderOutcomeMeasures?.includes(label) &&
                newerOutcomeMeasures?.includes(label)
              }
              breakPill
            />
          ))}
        </div>
        <div className={styles.divider} />
        <div className={styles.divider} />
        <div>
          <div className={styles.paneTitle}>
            Final testing will be performed after
          </div>
          <div className={styles.testingPerformedInputWrapper}>
            <InputMockup
              value={trialOlderVersion.final_testing_performed}
              changedOld={
                !(
                  trialOlderVersion.final_testing_performed ===
                  trialNewerVersion.final_testing_performed
                )
              }
            />
          </div>
        </div>
        <div>
          <div className={styles.paneTitle}>
            Final testing will be performed after
          </div>
          <div className={styles.testingPerformedInputWrapper}>
            <InputMockup
              value={trialNewerVersion.final_testing_performed}
              changedNew={
                !(
                  trialOlderVersion.final_testing_performed ===
                  trialNewerVersion.final_testing_performed
                )
              }
            />
          </div>
        </div>
      </div>
    </TabPanel>
  );
};

export { OutcomeMeasures as default };
