import styles from './ExpandableGroup.module.scss';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import React, { useMemo } from 'react';
import {
  useDisclosureState,
  Disclosure,
  DisclosureContent,
} from 'reakit/Disclosure';
import {
  unstable_FormCheckbox as FormCheckbox,
  unstable_FormGroup as FormGroup,
} from 'reakit/Form';
import Checkbox from '@moonshineragency/ui/src/components/Checkbox/Checkbox';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import classNames from 'classnames';
import Button from '@moonshineragency/ui/src/components/Button/Button';

const ExpandableGroup = ({
  form,
  parentId,
  parentIdFromForm,
  getParentIdForKey,
  title,
  primaryTitle,
  name: groupName,
  primaryFieldName,
  items = [],
  checkedItemsFromForm = [],
  onChange,
}) => {
  const disclosure = useDisclosureState({ visible: false });
  const showSetPrimary =
    parentId === parentIdFromForm && parentId !== undefined;

  const handeSetPrimary = key => {
    const selectedParentId = getParentIdForKey(key);
    form.update(primaryFieldName, selectedParentId);
    onChange();
  };

  const hasCurrentGroupCheckedItems = useMemo(() => {
    const filteredItems = items.filter(item =>
      checkedItemsFromForm.includes(item.id),
    );

    return filteredItems.length > 0;
  }, [form]);

  const checkedItems = items.filter(item =>
    form.values[groupName].includes(item.id),
  );

  return (
    <FormGroup {...form} name={groupName}>
      <div className={styles.legend}>
        <Disclosure as="legend" name={groupName} {...disclosure}>
          <div className={styles.countWrapper}>
            {title}
            {form.values[groupName] && form.values[groupName].length > 0 && (
              <span
                className={classNames(styles.count, {
                  [styles.hideCount]: checkedItems.length === 0,
                })}
              >
                {checkedItems.length}
              </span>
            )}
          </div>
        </Disclosure>
        {hasCurrentGroupCheckedItems && (
          <>
            {showSetPrimary ? (
              <Button disabled theme="link" className={styles.primary}>
                {primaryTitle}
              </Button>
            ) : (
              <Button
                className={styles.setPrimary}
                theme="link"
                onClick={() => handeSetPrimary(title)}
              >
                Set as primary
              </Button>
            )}
          </>
        )}
        <Disclosure
          as="legend"
          name={groupName}
          {...disclosure}
          className={styles.arrowIconWrapper}
        >
          <IconWrapper
            className={classNames(styles.expandIcon, {
              [styles.rotatedIcon]: disclosure.visible,
            })}
            Icon={IconComponents.SmallArrowDown}
          />
        </Disclosure>
      </div>
      <DisclosureContent {...disclosure} className={styles.items}>
        {items.map(({ id, name }) => (
          <FormCheckbox
            as={Checkbox}
            {...form}
            value={id}
            name={groupName}
            label={name}
          />
        ))}
      </DisclosureContent>
    </FormGroup>
  );
};

export default ExpandableGroup;
