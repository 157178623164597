import styles from 'sections/admin/trialEdit/TrialEditSections.module.scss';
import Client from 'api/client';
import useStore from 'store';
import React, { useState } from 'react';
import { Separator } from 'reakit/Separator';
import {
  unstable_useFormState as useFormState,
  unstable_FormSubmitButton as FormSubmitButton,
  unstable_FormLabel as FormLabel,
} from 'reakit/Form';
import { getDate, getYear, getHours, getMinutes, format } from 'date-fns';
import classNames from 'classnames';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import Form, {
  renderInputErrorFor,
} from '@moonshineragency/ui/src/components/Form/Form';
import Textarea from '@moonshineragency/ui/src/components/Textarea/Textarea';
import Content from '@moonshineragency/ui/src/components/Content/Content';

const TrialComments = ({ trialForm, trialCommentsData }) => {
  const [comments, setComments] = useState([]);
  const [startComment, setStartComment] = useState(false);
  const store = useStore();
  const id = trialForm.values.nct_id;
  const form = useFormState({
    values: {
      text: '',
    },
    onValidate: values => {
      let errors = {};
      if (!values.text && !form.submitting && startComment) {
        errors = {
          ...errors,
          text: renderInputErrorFor('Please enter comment'),
        };
      }
      if (Object.keys(errors).length) {
        throw errors;
      }
    },
    onSubmit: async values => {
      try {
        await Client.writeComments.post(id, { text: values.text });
        const getCommentsData = async (nctId, setCommentsData) => {
          const { data } = await Client.trialComments.get(nctId);

          setCommentsData({
            comments: data,
          });
        };
        getCommentsData(id, setComments);
        setStartComment(false);
        form.update('text', '');
      } catch (errors) {
        console.log(errors);
      }
    },
  });
  const addCommentArea = (
    <div className={styles.linksWrapper}>
      <Button
        theme="link"
        className={styles.cancel}
        onClick={() => {
          setStartComment(false);
          form.update('text', '');
        }}
      >
        Cancel
      </Button>
      <FormSubmitButton as={Button} theme="primary" size="default" {...form}>
        Save comment
      </FormSubmitButton>
    </div>
  );
  const startCommentArea = (
    <div className={styles.startWrapper}>
      <Button
        theme="secondary"
        size="default"
        onClick={() => setStartComment(true)}
        className={styles.addButton}
      >
        Add comment
      </Button>
      <div className={styles.separator}>
        <Separator />
      </div>
    </div>
  );
  const commentsToShow = comments?.length === 0 ? trialCommentsData : comments;
  return (
    <>
      {startComment ? (
        <Form submitArea={addCommentArea} form={form}>
          <FormLabel
            {...form}
            name="text"
            className={classNames(styles.textLabel, {
              [styles.errorLabel]:
                Object.keys(form.errors).length > 0 &&
                Object.keys(form.touched).length > 0 &&
                !form.valid,
            })}
          >
            Add comment
          </FormLabel>
          <Textarea {...form} name="text" rows="5" label="" />
        </Form>
      ) : (
        startCommentArea
      )}

      {commentsToShow.comments?.length ? (
        commentsToShow.comments?.map(comment => {
          const time = new Date(comment.created_at);
          const commentDate = {
            day: getDate(time),
            month: format(time, 'LLLL'),
            year: getYear(time),
            hour: getHours(time),
            minutes: (getMinutes(time) < 10 ? '0' : '') + getMinutes(time),
          };
          const commentedUserId = comment.user.id;
          const loggedUserId = store.user.user.id;
          return (
            <div key={comment.id} className={styles.historyWrapper}>
              <Content variant="light" theme="secondary" size="uiText50">
                {commentDate.day || ''} {commentDate.month || ''}{' '}
                {commentDate.year || ''}, {commentDate.hour || ''}:
                {commentDate.minutes || ''}
              </Content>
              <Content variant="light" theme="regular" size="uiText75">
                {commentedUserId === loggedUserId
                  ? 'You'
                  : `${comment.user.first_name} ${comment.user.last_name}`}
                <span className={styles.text}> commented</span>{' '}
              </Content>
              <Content variant="light" theme="regular" size="uiText75">
                {comment.text}
              </Content>

              <div className={styles.separator}>
                <Separator />
              </div>
            </div>
          );
        })
      ) : (
        <Content variant="light" theme="regular" size="uiText75">
          No comments yet.
        </Content>
      )}
    </>
  );
};
export default TrialComments;
