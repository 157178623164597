import styles from './VersionComparisonSelector.module.scss';
import DataView from 'components/DataView/DataView';
import React from 'react';
import { usePopoverState, PopoverDisclosure, Popover } from 'reakit/Popover';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import { getDate, getYear, getHours, getMinutes } from 'date-fns';
import classNames from 'classnames';

const VersionComparisonSelector = ({
  olderVersionNumber,
  newerVersionNumber,
  setOlderVersionNumber,
  setNewerVersionNumber,
  olderVersionModifiedAt,
  newerVersionModifiedAt,
  versions,
}) => {
  const olderVersionSelectPopover = usePopoverState();
  const newerVersionSelectPopover = usePopoverState();

  const versionsForOlderTrials = versions?.filter(version => {
    return version < newerVersionNumber;
  });
  const versionsForNewerTrials = versions?.filter(version => {
    return version > olderVersionNumber;
  });

  const getFormattedDate = dateString => {
    const dateToFormat = new Date(dateString);

    const date = getDate(dateToFormat);
    const shortMonth = dateToFormat.toLocaleString('en-us', {
      month: 'short',
    });
    const year = getYear(dateToFormat);
    const hours = getHours(dateToFormat);
    const minutes =
      (getMinutes(dateToFormat) < 10 ? '0' : '') + getMinutes(dateToFormat);

    return `${date} ${shortMonth} ${year} ${hours}:${minutes}`;
  };

  return (
    <div className={styles.paneWrapper}>
      <div>
        <PopoverDisclosure
          className={styles.popoverDisclosureOld}
          {...olderVersionSelectPopover}
        >
          <Heading size="h4" theme="primary">
            Version {olderVersionNumber}
            <IconComponents.SmallArrowDown
              className={classNames(styles.expandButtonIcon, {
                [styles.rotatedIcon]: olderVersionSelectPopover.visible,
              })}
            />
          </Heading>
        </PopoverDisclosure>
        <Content
          size="uiText50"
          theme="neutral"
          className={styles.infoSectionValueOld}
        >
          {olderVersionModifiedAt
            ? `Older, saved on ${getFormattedDate(olderVersionModifiedAt)}`
            : 'Older'}
        </Content>
      </div>
      <Popover {...olderVersionSelectPopover} className={styles.popover}>
        <DataView
          className={styles.dataView}
          popover={olderVersionSelectPopover}
          name="olderVersion"
        >
          {versionsForOlderTrials?.map(version => (
            <Button
              className={styles.chooseVersionButton}
              theme="link"
              onClick={() => {
                setOlderVersionNumber(version);
                olderVersionSelectPopover.hide();
              }}
            >
              Version {version}
            </Button>
          ))}
        </DataView>
      </Popover>
      <div>
        <PopoverDisclosure
          className={styles.popoverDisclosureNew}
          {...newerVersionSelectPopover}
        >
          <Heading size="h4" theme="primary">
            Version {newerVersionNumber}
            <IconComponents.SmallArrowDown
              className={classNames(styles.expandButtonIcon, {
                [styles.rotatedIcon]: newerVersionSelectPopover.visible,
              })}
            />
          </Heading>
        </PopoverDisclosure>
        <Content
          size="uiText50"
          theme="neutral"
          className={styles.infoSectionValueNew}
        >
          {newerVersionModifiedAt
            ? `Newer, saved on ${getFormattedDate(newerVersionModifiedAt)}`
            : 'Newer'}
        </Content>
      </div>
      <Popover {...newerVersionSelectPopover} className={styles.popover}>
        <DataView
          className={styles.dataView}
          popover={newerVersionSelectPopover}
          name="olderVersion"
        >
          {versionsForNewerTrials?.map(version => (
            <Button
              className={styles.chooseVersionButton}
              theme="link"
              onClick={() => {
                setNewerVersionNumber(version);
                newerVersionSelectPopover.hide();
              }}
            >
              Version {version}
            </Button>
          ))}
        </DataView>
      </Popover>
    </div>
  );
};

export default VersionComparisonSelector;
