import styles from './TrialType.module.scss';
import CheckboxMockup from 'components/admin/trialEdit/TrialCompare/components/CheckboxMockup/CheckboxMockup';
import RadioMockup from 'components/admin/trialEdit/TrialCompare/components/RadioMockup/RadioMockup';
import VersionComparisonSelector from 'components/admin/trialEdit/TrialCompare/components/VersionComparisonSelector/VersionComparisonSelector';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import React from 'react';
import { TabPanel } from 'reakit/Tab';
import classNames from 'classnames';

const mapToParentCategory = intervention => {
  switch (intervention.parent_id) {
    case 1:
      return 'Biological/Cell based';
    case 2:
      return 'Drug';
    case 3:
      return 'Rehabilitation';
    case 4:
      return 'Surgery';
    case 5:
      return 'Technology';
    default:
      return 'Psychosocial/other';
  }
};

const TrialType = ({
  trialOlderVersion,
  trialNewerVersion,
  tab,
  interventionsData,
  olderVersionNumber,
  newerVersionNumber,
  setOlderVersionNumber,
  setNewerVersionNumber,
}) => {
  const allocationListOne = [
    {
      label: 'Randomized',
      name: 'randomizedOne',
    },
    {
      label: 'Non-Randomized',
      name: 'nonRandomizedOne',
    },
    {
      label: 'Not available',
      name: 'notAvailableOne',
    },
  ];

  const allocationListTwo = [
    {
      label: 'Randomized',
      name: 'randomizedTwo',
    },
    {
      label: 'Non-Randomized',
      name: 'nonRandomizedTwo',
    },
    {
      label: 'Not available',
      name: 'notAvailableTwo',
    },
  ];

  const diffCustomStyles = {
    marker: {
      display: 'none',
    },
    titleBlock: {
      display: 'none',
    },
    diffAdded: {
      'border-radius': '6px',
      padding: '10px',
    },
    diffRemoved: {
      'border-radius': '6px',
      padding: '10px',
    },
  };

  const olderPrimaryInterventionId =
    trialOlderVersion?.primary_intervention?.id;
  const newerPrimaryInterventionId =
    trialNewerVersion?.primary_intervention?.id;

  const newerTrialCheckedInterventions = trialNewerVersion?.interventions
    ? trialNewerVersion.interventions.map(intervention => intervention.id)
    : [];

  const olderTrialCheckedInterventions = trialOlderVersion?.interventions
    ? trialOlderVersion.interventions.map(intervention => intervention.id)
    : [];

  const filteredInterventionsData = interventionsData.filter(
    iD => iD.parent_id,
  );

  const reducedInterventionsData = filteredInterventionsData.reduce(
    (res, curr) => {
      const parentCategory = mapToParentCategory(curr);
      if (!res[parentCategory]) {
        res[parentCategory] = [{ ...curr }];
      } else {
        res[parentCategory].push(curr);
      }
      return res;
    },
    {},
  );

  const newerDetailedDespricption = trialNewerVersion.detailed_description
    ? trialNewerVersion.detailed_description
    : '';

  const olderDetailedDespricption = trialOlderVersion.detailed_description
    ? trialOlderVersion.detailed_description
    : '';

  const isDetailedDescriptionChanged = !(
    JSON.stringify(trialNewerVersion.detailed_description) ===
    JSON.stringify(trialOlderVersion.detailed_description)
  );

  return (
    <TabPanel {...tab}>
      <VersionComparisonSelector
        olderVersionNumber={olderVersionNumber}
        newerVersionNumber={newerVersionNumber}
        setOlderVersionNumber={setOlderVersionNumber}
        setNewerVersionNumber={setNewerVersionNumber}
        olderVersionModifiedAt={trialOlderVersion.modified_at}
        newerVersionModifiedAt={trialNewerVersion.modified_at}
        versions={trialNewerVersion.versions}
      />
      <div className={styles.paneWrapper}>
        <div className={styles.divider} />
        <div className={styles.divider} />
        <div>
          <div>
            <div className={styles.paneTitle}>Type</div>
            <div className={styles.radioButtonGroup}>
              <RadioMockup
                name="ObservationalTwo"
                label="Observational"
                checked={trialOlderVersion.study_type === 'observational'}
                changedOld={
                  trialOlderVersion.study_type === 'observational' &&
                  !(
                    trialOlderVersion.study_type ===
                    trialNewerVersion.study_type
                  )
                }
              />
              <RadioMockup
                name="InterventionalTwo"
                label="Interventional"
                checked={trialOlderVersion.study_type === 'interventional'}
                changedOld={
                  trialOlderVersion.study_type === 'interventional' &&
                  !(
                    trialOlderVersion.study_type ===
                    trialNewerVersion.study_type
                  )
                }
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className={styles.paneTitle}>Type</div>
            <div className={styles.radioButtonGroup}>
              <RadioMockup
                name="ObservationalOne"
                label="Observational"
                checked={trialNewerVersion.study_type === 'observational'}
                changedNew={
                  trialNewerVersion.study_type === 'observational' &&
                  !(
                    trialOlderVersion.study_type ===
                    trialNewerVersion.study_type
                  )
                }
              />
              <RadioMockup
                name="InterventionalOne"
                label="Interventional"
                checked={trialNewerVersion.study_type === 'interventional'}
                changedNew={
                  trialNewerVersion.study_type === 'interventional' &&
                  !(
                    trialOlderVersion.study_type ===
                    trialNewerVersion.study_type
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.divider} />
        <div>
          <div>
            <div className={styles.paneTitle}>Allocations</div>
            <div className={styles.radioButtonGroup}>
              {allocationListOne.map(item => (
                <RadioMockup
                  name={item.name}
                  label={item.label}
                  checked={item.label === trialOlderVersion.allocation}
                  changedOld={
                    trialOlderVersion.allocation === item.label &&
                    !(
                      trialOlderVersion.allocation ===
                      trialNewerVersion.allocation
                    )
                  }
                />
              ))}
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className={styles.paneTitle}>Allocations</div>
            <div className={styles.radioButtonGroup}>
              {allocationListTwo.map(item => (
                <RadioMockup
                  name={item.name}
                  label={item.label}
                  checked={item.label === trialNewerVersion.allocation}
                  changedNew={
                    trialNewerVersion.allocation === item.label &&
                    !(
                      trialOlderVersion.allocation ===
                      trialNewerVersion.allocation
                    )
                  }
                />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.divider} />
      </div>
      <div>
        <div>
          <div className={styles.paneTitle}>What’s involved</div>
          {isDetailedDescriptionChanged ? (
            <ReactDiffViewer
              styles={diffCustomStyles}
              compareMethod={DiffMethod.WORDS}
              oldValue={olderDetailedDespricption}
              newValue={newerDetailedDespricption}
              hideLineNumbers
            />
          ) : (
            <div>{trialNewerVersion.detailed_description}</div>
          )}
        </div>
      </div>
      <div className={styles.paneWrapper}>
        <div className={styles.divider} />
        <div className={styles.divider} />
        {Object.keys(reducedInterventionsData).map(key => {
          const interventionGroup = reducedInterventionsData[key];
          const interventionGroupParentId = interventionGroup[0]?.parent_id;

          return (
            <>
              <div>
                <div className={styles.paneTitle}>
                  {key}{' '}
                  {interventionGroupParentId === olderPrimaryInterventionId && (
                    <span
                      className={classNames(styles.primaryIntervention, {
                        [styles.primaryChangedOld]:
                          olderPrimaryInterventionId !==
                          newerPrimaryInterventionId,
                      })}
                    >
                      Primary intervention
                    </span>
                  )}
                </div>
                {interventionGroup.map(intervention => (
                  <CheckboxMockup
                    name={intervention.name}
                    checked={olderTrialCheckedInterventions.includes(
                      intervention.id,
                    )}
                    changedOld={
                      olderTrialCheckedInterventions.includes(
                        intervention.id,
                      ) &&
                      !newerTrialCheckedInterventions.includes(intervention.id)
                    }
                  />
                ))}
              </div>
              <div>
                <div className={styles.paneTitle}>
                  {key}{' '}
                  {interventionGroupParentId === newerPrimaryInterventionId && (
                    <span
                      className={classNames(styles.primaryIntervention, {
                        [styles.primaryChangedOld]:
                          newerPrimaryInterventionId !==
                          olderPrimaryInterventionId,
                      })}
                    >
                      Primary intervention
                    </span>
                  )}
                </div>
                {interventionGroup.map(intervention => (
                  <CheckboxMockup
                    name={intervention.name}
                    checked={newerTrialCheckedInterventions.includes(
                      intervention.id,
                    )}
                    changedNew={
                      newerTrialCheckedInterventions.includes(
                        intervention.id,
                      ) &&
                      !olderTrialCheckedInterventions.includes(intervention.id)
                    }
                  />
                ))}
              </div>
              <div className={styles.divider} />
              <div className={styles.divider} />
            </>
          );
        })}
      </div>
    </TabPanel>
  );
};

export { TrialType as default };
