import PrivateRoute from './components/PrivateRoute.js';
import AdminTrialsList from './components/trials/list/AdminTrialsList.js';
import AdminUsersList from './components/user/list/AdminUsersList.js';
import Analytics from './components/analytics/Analytics.js';
import EditUser from './components/user/list/EditUser';
import CreateUser from './components/user/list/CreateUser.js';
import ResetPassword from './components/user/list/ResetPassword.js';
import { AuthorizationContextProvider } from 'contextProvider';
import TrialCompare from 'components/admin/trialEdit/TrialCompare/TrialCompare.js';
import TrialEdit from 'components/admin/trialEdit/trialEdit';

import React from 'react';
import { Router } from '@reach/router';

const App = () => {
  return (
    <>
      <Router basepath="/admin">
        <PrivateRoute path="/trials/list" component={AdminTrialsList} />
        <PrivateRoute
          path="/trials/:nct_id/compare/:new_version/:old_version"
          component={TrialCompare}
        />
        <ResetPassword path="/users/reset-password" />
        <PrivateRoute path="/analytics" component={Analytics} />
        <PrivateRoute
          path="/users/list/:id"
          component={EditUser}
          scope="manage users"
        />
        <PrivateRoute
          path="/users/list/createUser"
          component={CreateUser}
          scope="manage users"
        />
        <PrivateRoute
          path="/users/list"
          component={AdminUsersList}
          scope="manage users"
        />
      </Router>

      <AuthorizationContextProvider>
        <Router basepath="/admin">
          <PrivateRoute path="/trials/:nct_id/edit" component={TrialEdit} />
        </Router>
      </AuthorizationContextProvider>
    </>
  );
};
export default App;
