/* eslint-disable jsx-a11y/no-onchange */
import styles from 'sections/admin/trialEdit/TrialEditSections.module.scss';
import React, { useState } from 'react';
import InputField from '@moonshineragency/ui/src/components/InputField/InputField';
import FormRadioGroup from '@moonshineragency/ui/src/components/InputField/components/FormRadioGroup/FormRadioGroup';
import FormCheckboxGroup from '@moonshineragency/ui/src/components/InputField/components/FormCheckboxGroup/FormCheckboxGroup';
import { TabPanel } from 'reakit/Tab';
import Select from '@moonshineragency/ui/src/components/Select/Select';
import {
  unstable_FormGroup as FormGroup,
  unstable_FormLabel as FormLabel,
  unstable_FormMessage as FormMessage,
} from 'reakit';

const Eligibility = ({ injuriesData, form, tab, onChange }) => {
  const { inclusion_criteria: baseInclusionCriteria = [] } = form.values;
  const [selectedIndex, setSelectedIndex] = useState(null);

  const inclusionCriteria =
    [...baseInclusionCriteria]?.map(item => ({
      value: item,
      label: item,
    })) ?? [];
  const filteredInjuriesData = injuriesData.filter(injury => injury.parent_id);

  const traumaticInjuries = filteredInjuriesData.filter(
    injury => injury.parent_id === 1,
  );
  const nonTraumaticInjuries = filteredInjuriesData.filter(
    injury => injury.parent_id === 2,
  );

  const traumaticInjuriesOptions = traumaticInjuries.map(injury => ({
    label: injury.name,
    value: injury.id,
  }));

  const nonTraumaticInjuriesOptions = nonTraumaticInjuries.map(injury => ({
    label: injury.name,
    value: injury.id,
  }));

  const handleTimeSinceInjuryUnit = (
    { target: { value } },
    trialForm,
    fieldName,
  ) => {
    trialForm.update(fieldName, value);
  };

  const injuryLevel = [
    { label: '', value: null },
    {
      label: 'C1',
      value: 'C1',
    },
    {
      label: 'C2',
      value: 'C2',
    },
    {
      label: 'C3',
      value: 'C3',
    },
    {
      label: 'C4',
      value: 'C4',
    },
    {
      label: 'C5',
      value: 'C5',
    },
    {
      label: 'C6',
      value: 'C6',
    },
    {
      label: 'C7',
      value: 'C7',
    },
    {
      label: 'C8',
      value: 'C8',
    },
    {
      label: 'T1',
      value: 'T1',
    },
    {
      label: 'T2',
      value: 'T2',
    },
    {
      label: 'T3',
      value: 'T3',
    },
    {
      label: 'T4',
      value: 'T4',
    },
    {
      label: 'T5',
      value: 'T5',
    },
    {
      label: 'T6',
      value: 'T6',
    },
    {
      label: 'T7',
      value: 'T7',
    },
    {
      label: 'T8',
      value: 'T8',
    },
    {
      label: 'T9',
      value: 'T9',
    },
    {
      label: 'T10',
      value: 'T10',
    },
    {
      label: 'T11',
      value: 'T11',
    },
    {
      label: 'T12',
      value: 'T12',
    },
    {
      label: 'L1',
      value: 'L1',
    },
    {
      label: 'L2',
      value: 'L2',
    },
    {
      label: 'L3',
      value: 'L3',
    },
    {
      label: 'L4',
      value: 'L4',
    },
    {
      label: 'L5',
      value: 'L5',
    },
    {
      label: 'S1',
      value: 'S1',
    },
    {
      label: 'S2',
      value: 'S2',
    },
    {
      label: 'S3',
      value: 'S3',
    },
    {
      label: 'S4',
      value: 'S4',
    },
    {
      label: 'S5',
      value: 'S5',
    },
  ];

  const handleInjuryLevel = ({ target: { value } }, trialForm, fieldName) => {
    trialForm.update(fieldName, value);
    const index = injuryLevel.findIndex(v => v.value === value);
    setSelectedIndex(index);
  };

  const customStyles = {
    multiValue: provided => ({
      ...provided,
      borderRadius: 4,
      color: '#879199',
      backgroundColor: '#EBF0F5',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: '#EBF0F5',
      },
    }),
    valueContainer: provided => ({
      ...provided,
      cursor: 'pointer',
    }),
    multiValueRemove: provided => ({
      ...provided,
      ':hover': {
        backgroundColor: '#EBF0F5',
        color: '#41474D',
      },
    }),
  };

  return (
    <TabPanel {...tab}>
      <div>
        <FormRadioGroup
          name="sex"
          title="Gender/Sex"
          items={[
            {
              label: 'All',
              value: 'All',
            },
            {
              label: 'Male',
              value: 'Male',
            },
            {
              label: 'Female',
              value: 'Female',
            },
          ]}
          {...form}
        />
        <FormGroup {...form} name="age">
          <FormLabel
            {...form}
            name="age"
            label="Age"
            className={styles.title}
          />
          <div className={styles.twoInputWrapper}>
            <InputField name="age_from" {...form} />
            <span>to</span>
            <InputField name="age_to" {...form} />
            <span>years</span>
          </div>
        </FormGroup>
        <FormGroup {...form} name="injury_level">
          <FormLabel
            {...form}
            name="injury_level"
            label="Injury level"
            className={styles.title}
          />
          <div className={styles.twoInputWrapper}>
            <select
              onChange={event => {
                handleInjuryLevel(event, form, 'injury_level_from');
              }}
              value={form.values.injury_level_from}
              className={styles.eligibilityTimeSinceInjurySelect}
            >
              {injuryLevel.map(item => (
                <option value={item.value}>{item.label}</option>
              ))}
            </select>
            <span>to</span>
            <select
              onChange={event =>
                handleInjuryLevel(event, form, 'injury_level_to')
              }
              value={form.values.injury_level_to}
              className={styles.eligibilityTimeSinceInjurySelect}
            >
              {injuryLevel.slice(selectedIndex).map(item => (
                <option value={item.value}>{item.label}</option>
              ))}
            </select>
            <FormMessage {...form} name="timeSinceInjuryTo" />
          </div>
        </FormGroup>
        <FormCheckboxGroup
          name="ais"
          title="Severity (AIS)"
          items={[
            {
              label: 'AIS-A',
              value: 'ais_a',
            },
            {
              label: 'AIS-B',
              value: 'ais_b',
            },
            {
              label: 'AIS-C',
              value: 'ais_c',
            },
            {
              label: 'AIS-D',
              value: 'ais_d',
            },
            {
              label: 'AIS-E',
              value: 'ais_e',
            },
          ]}
          {...form}
          className={styles.selectLabel}
        />
        <FormGroup {...form} name="time_since_injury">
          <FormLabel
            {...form}
            name="time_since_injury"
            label="Time since injury"
            className={styles.title}
          />
          <div className={styles.fourInputWrapper}>
            <InputField name="time_since_injury_from" {...form} noMessage />
            <select
              onChange={event =>
                handleTimeSinceInjuryUnit(
                  event,
                  form,
                  'time_since_injury_from_unit',
                )
              }
              value={form.values.time_since_injury_from_unit}
              className={styles.eligibilityTimeSinceInjurySelect}
            >
              <option value="hours">Hours</option>
              <option value="days">Days</option>
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
              <option value="years">Years</option>
            </select>
            <FormMessage {...form} name="timeSinceInjuryFrom" />
            <span>to</span>
            <InputField name="time_since_injury_to" {...form} noMessage />
            <select
              onChange={event =>
                handleTimeSinceInjuryUnit(
                  event,
                  form,
                  'time_since_injury_to_unit',
                )
              }
              value={form.values.time_since_injury_to_unit}
              className={styles.eligibilityTimeSinceInjurySelect}
            >
              <option value="hours">Hours</option>
              <option value="days">Days</option>
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
              <option value="years">Years</option>
            </select>
            <FormMessage {...form} name="timeSinceInjuryTo" />
          </div>
        </FormGroup>
        <FormCheckboxGroup
          name="injuries"
          title="Traumatic injuries"
          items={traumaticInjuriesOptions}
          {...form}
          className={styles.selectLabel}
        />
        <FormCheckboxGroup
          name="injuries"
          title="Non-traumatic injuries"
          items={nonTraumaticInjuriesOptions}
          {...form}
          className={styles.selectLabel}
        />
        <FormRadioGroup
          name="healthy_volunteers"
          title="Accepts healthy volunteers"
          items={[
            {
              label: 'No',
              value: false,
            },
            {
              label: 'Yes',
              value: true,
            },
          ]}
          {...form}
        />
        <Select
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
          }}
          name="inclusion_criteria"
          label="Participants must"
          options={inclusionCriteria}
          form={form}
          className={styles.createableSelectInclusion}
          onValueChange={onChange}
          placeholder="New inclusion criteria..."
          styles={customStyles}
        />
      </div>
    </TabPanel>
  );
};

export { Eligibility as default };
