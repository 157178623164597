import styles from './FormRadioGroup.module.scss';
import React from 'react';

import {
  unstable_FormLabel as FormLabel,
  unstable_FormRadioGroup as FormRadioGroup,
  unstable_FormRadio as FormRadio,
} from 'reakit/Form';
import Radio from '@moonshineragency/ui/src/components/Radio/Radio';

const RadioGroup = ({ name, title, items = [], ...form }) => {
  return (
    <FormRadioGroup className={styles.container} {...form} name={name}>
      <FormLabel {...form} as="legend" name={name} className={styles.legend}>
        {title}
      </FormLabel>
      <div className={styles.items}>
        {items.map(({ value, label }) => (
          <FormRadio
            as={Radio}
            {...form}
            name={name}
            value={value}
            label={label}
          />
        ))}
      </div>
    </FormRadioGroup>
  );
};

export { RadioGroup as default };
