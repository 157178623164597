import styles from './Eligibility.module.scss';
import CheckboxMockup from 'components/admin/trialEdit/TrialCompare/components/CheckboxMockup/CheckboxMockup';
import RadioMockup from 'components/admin/trialEdit/TrialCompare/components//RadioMockup/RadioMockup';
import InputMockup from 'components/admin/trialEdit/TrialCompare/components//InputMockup/InputMockup';
import VersionComparisonSelector from 'components/admin/trialEdit/TrialCompare/components/VersionComparisonSelector/VersionComparisonSelector';
import PillMockup from 'components/admin/trialEdit/TrialCompare/components//PillMockup/PillMockup';
import React from 'react';
import { TabPanel } from 'reakit/Tab';

const Eligibility = ({
  trialOlderVersion,
  trialNewerVersion,
  tab,
  injuriesData,
  olderVersionNumber,
  newerVersionNumber,
  setOlderVersionNumber,
  setNewerVersionNumber,
}) => {
  const inclusionCriteriaOne = trialOlderVersion.inclusion_criteria;
  const inclusionCriteriaTwo = trialNewerVersion.inclusion_criteria;

  const baseInclusionCriteriaOne =
    inclusionCriteriaOne == null ? ['nothing chosen...'] : inclusionCriteriaOne;

  const baseInclusionCriteriaTwo =
    inclusionCriteriaTwo == null ? ['nothing chosen...'] : inclusionCriteriaTwo;

  const filteredInjuriesData = injuriesData?.filter(injury => injury.parent_id);

  const traumaticInjuries = filteredInjuriesData?.filter(
    injury => injury.parent_id === 1,
  );
  const nonTraumaticInjuries = filteredInjuriesData?.filter(
    injury => injury.parent_id === 2,
  );

  const traumaticInjuriesOptions = traumaticInjuries?.map(injury => ({
    label: injury.name,
    value: injury.id,
  }));

  const nonTraumaticInjuriesOptions = nonTraumaticInjuries?.map(injury => ({
    label: injury.name,
    value: injury.id,
  }));

  const aisList = [
    {
      label: 'AIS-A',
      value: 'ais_a',
    },
    {
      label: 'AIS-B',
      value: 'ais_b',
    },
    {
      label: 'AIS-C',
      value: 'ais_c',
    },
    {
      label: 'AIS-D',
      value: 'ais_d',
    },
    {
      label: 'AIS-E',
      value: 'ais_e',
    },
  ];

  const genderListOne = [
    {
      label: 'All',
      name: 'AllOne',
    },
    {
      label: 'Male',
      name: 'MaleOne',
    },
    {
      label: 'Female',
      name: 'FemaleOne',
    },
  ];

  const genderListTwo = [
    {
      label: 'All',
      name: 'AllTwo',
    },
    {
      label: 'Male',
      name: 'MaleTwo',
    },
    {
      label: 'Female',
      name: 'FemaleTwo',
    },
  ];

  return (
    <TabPanel {...tab}>
      <VersionComparisonSelector
        olderVersionNumber={olderVersionNumber}
        newerVersionNumber={newerVersionNumber}
        setOlderVersionNumber={setOlderVersionNumber}
        setNewerVersionNumber={setNewerVersionNumber}
        olderVersionModifiedAt={trialOlderVersion.modified_at}
        newerVersionModifiedAt={trialNewerVersion.modified_at}
        versions={trialNewerVersion.versions}
      />
      <div className={styles.paneWrapper}>
        <div className={styles.divider} />
        <div className={styles.divider} />
        <div>
          <div className={styles.paneTitle}>Gender/Sex</div>
          <div className={styles.radioButtonGroup}>
            {genderListOne.map(item => (
              <RadioMockup
                name={item.name}
                label={item.label}
                checked={item.label === trialOlderVersion.sex}
                changedOld={
                  trialOlderVersion.sex === item.label &&
                  !(trialOlderVersion.sex === trialNewerVersion.sex)
                }
              />
            ))}
          </div>
        </div>
        <div>
          <div>
            <div className={styles.paneTitle}>Gender/Sex</div>
            <div className={styles.radioButtonGroup}>
              {genderListTwo.map(item => (
                <RadioMockup
                  name={item.name}
                  label={item.label}
                  checked={item.label === trialNewerVersion.sex}
                  changedNew={
                    trialNewerVersion.sex === item.label &&
                    !(trialOlderVersion.sex === trialNewerVersion.sex)
                  }
                />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.divider} />
        <div>
          <div>
            <div className={styles.paneTitle}>Age</div>

            <div className={styles.inputWrapper}>
              <InputMockup
                value={trialOlderVersion.age_from}
                changedOld={
                  !(trialOlderVersion.age_from === trialNewerVersion.age_from)
                }
              />
              <span>to</span>
              <InputMockup
                value={trialOlderVersion.age_to}
                changedOld={
                  !(trialOlderVersion.age_to === trialNewerVersion.age_to)
                }
              />
              <span>years</span>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className={styles.paneTitle}>Age</div>

            <div className={styles.inputWrapper}>
              <InputMockup
                value={trialNewerVersion.age_from}
                changedNew={
                  !(trialOlderVersion.age_from === trialNewerVersion.age_from)
                }
              />
              <span>to</span>
              <InputMockup
                value={trialNewerVersion.age_to}
                changedNew={
                  !(trialOlderVersion.age_to === trialNewerVersion.age_to)
                }
              />
              <span>years</span>
            </div>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.divider} />
        <div>
          <div>
            <div className={styles.paneTitle}>Injury level</div>

            <div className={styles.inputWrapper}>
              <InputMockup
                value={trialOlderVersion.injury_level_from}
                changedOld={
                  !(
                    trialOlderVersion.injury_level_from ===
                    trialNewerVersion.injury_level_from
                  )
                }
              />
              <span>to</span>
              <InputMockup
                value={trialOlderVersion.injury_level_to}
                changedOld={
                  !(
                    trialOlderVersion.injury_level_to ===
                    trialNewerVersion.injury_level_to
                  )
                }
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className={styles.paneTitle}>Injury level</div>

            <div className={styles.inputWrapper}>
              <InputMockup
                value={trialNewerVersion.injury_level_from}
                changedNew={
                  !(
                    trialOlderVersion.injury_level_from ===
                    trialNewerVersion.injury_level_from
                  )
                }
              />
              <span>to</span>
              <InputMockup
                value={trialNewerVersion.injury_level_to}
                changedNew={
                  !(
                    trialOlderVersion.injury_level_to ===
                    trialNewerVersion.injury_level_to
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.divider} />
        <div>
          <div className={styles.paneTitle}>Severity (AIS)</div>
          {aisList.map(item => {
            return (
              <CheckboxMockup
                name={item.label}
                checked={trialOlderVersion.ais?.includes(item.value)}
                changedOld={
                  !trialOlderVersion.ais?.includes(item.value) &&
                  trialNewerVersion.ais?.includes(item.value)
                }
              />
            );
          })}
        </div>
        <div>
          <div className={styles.paneTitle}>Severity (AIS)</div>
          {aisList.map(item => {
            return (
              <CheckboxMockup
                name={item.label}
                checked={trialNewerVersion.ais?.includes(item.value)}
                changedNew={
                  !trialNewerVersion.ais?.includes(item.value) &&
                  trialOlderVersion.ais?.includes(item.value)
                }
              />
            );
          })}
        </div>
        <div className={styles.divider} />
        <div className={styles.divider} />
        <div>
          <div className={styles.paneTitle}>Time since injury</div>

          <div className={styles.timeSinceInjuryInputWrapper}>
            <InputMockup
              value={trialOlderVersion.time_since_injury_from}
              changedOld={
                !(
                  trialOlderVersion.time_since_injury_from ===
                  trialNewerVersion.time_since_injury_from
                )
              }
            />
            <InputMockup
              value={trialOlderVersion.time_since_injury_from_unit ?? 'Hours'}
              changedOld={
                !(
                  trialOlderVersion.time_since_injury_from_unit ===
                  trialNewerVersion.time_since_injury_from_unit
                )
              }
            />
            <span>to</span>
            <InputMockup
              value={trialOlderVersion.time_since_injury_to}
              changedOld={
                !(
                  trialOlderVersion.time_since_injury_to ===
                  trialNewerVersion.time_since_injury_to
                )
              }
            />
            <InputMockup
              value={trialOlderVersion.time_since_injury_to_unit ?? 'Hours'}
              changedOld={
                !(
                  trialOlderVersion.time_since_injury_to_unit ===
                  trialNewerVersion.time_since_injury_to_unit
                )
              }
            />
          </div>
        </div>
        <div>
          <div className={styles.paneTitle}>Time since injury</div>

          <div className={styles.timeSinceInjuryInputWrapper}>
            <InputMockup
              value={trialNewerVersion.time_since_injury_from}
              changedNew={
                !(
                  trialOlderVersion.time_since_injury_from ===
                  trialNewerVersion.time_since_injury_from
                )
              }
            />
            <InputMockup
              value={trialNewerVersion.time_since_injury_from_unit ?? 'Hours'}
              changedNew={
                !(
                  trialOlderVersion.time_since_injury_from_unit ===
                  trialNewerVersion.time_since_injury_from_unit
                )
              }
            />
            <span>to</span>
            <InputMockup
              value={trialNewerVersion.time_since_injury_to}
              changedNew={
                !(
                  trialOlderVersion.time_since_injury_to ===
                  trialNewerVersion.time_since_injury_to
                )
              }
            />
            <InputMockup
              value={trialNewerVersion.time_since_injury_to_unit ?? 'Hours'}
              changedNew={
                !(
                  trialOlderVersion.time_since_injury_to_unit ===
                  trialNewerVersion.time_since_injury_to_unit
                )
              }
            />
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.divider} />
        <div>
          <div className={styles.paneTitle}>Traumatic injuries</div>
          {traumaticInjuriesOptions?.map(item => {
            return (
              <CheckboxMockup
                name={item.label}
                checked={trialOlderVersion.injuries?.includes(item.value)}
                changedOld={
                  !trialOlderVersion.injuries?.includes(item.value) &&
                  trialNewerVersion.injuries?.includes(item.value)
                }
              />
            );
          })}
        </div>
        <div>
          <div className={styles.paneTitle}>Traumatic injuries</div>
          {traumaticInjuriesOptions?.map(item => {
            return (
              <CheckboxMockup
                name={item.label}
                checked={trialNewerVersion.injuries?.includes(item.value)}
                changedNew={
                  !trialOlderVersion.injuries?.includes(item.value) &&
                  trialNewerVersion.injuries?.includes(item.value)
                }
              />
            );
          })}
        </div>
        <div className={styles.divider} />
        <div className={styles.divider} />
        <div>
          <div className={styles.paneTitle}>Non-traumatic injuries</div>
          {nonTraumaticInjuriesOptions?.map(item => {
            return (
              <CheckboxMockup
                name={item.label}
                checked={trialOlderVersion.injuries?.includes(item.value)}
                changedOld={
                  !trialOlderVersion.injuries?.includes(item.value) &&
                  trialNewerVersion.injuries?.includes(item.value)
                }
              />
            );
          })}
        </div>
        <div>
          <div className={styles.paneTitle}>Non-traumatic injuries</div>
          {nonTraumaticInjuriesOptions?.map(item => {
            return (
              <CheckboxMockup
                name={item.label}
                checked={trialNewerVersion.injuries?.includes(item.value)}
                changedNew={
                  !trialOlderVersion.injuries?.includes(item.value) &&
                  trialNewerVersion.injuries?.includes(item.value)
                }
              />
            );
          })}
        </div>
        <div className={styles.divider} />
        <div className={styles.divider} />
        <div>
          <div>
            <div className={styles.paneTitle}>Accepts healthy volunteers</div>
            <div className={styles.radioButtonGroup}>
              <RadioMockup
                name="NoOne"
                label="No"
                checked={!trialOlderVersion.healthy_volunteers}
                changedOld={
                  trialOlderVersion.healthy_volunteers &&
                  !(
                    trialOlderVersion.healthy_volunteers ===
                    trialNewerVersion.healthy_volunteers
                  )
                }
              />
              <RadioMockup
                name="YesOne"
                label="Yes"
                checked={trialOlderVersion.healthy_volunteers}
                changedOld={
                  !trialOlderVersion.healthy_volunteers &&
                  !(
                    trialOlderVersion.healthy_volunteers ===
                    trialNewerVersion.healthy_volunteers
                  )
                }
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className={styles.paneTitle}>Accepts healthy volunteers</div>
            <div className={styles.radioButtonGroup}>
              <RadioMockup
                name="NoTwo"
                label="No"
                checked={!trialNewerVersion.healthy_volunteers}
                changedNew={
                  !trialNewerVersion.healthy_volunteers &&
                  !(
                    trialNewerVersion.healthy_volunteers ===
                    trialOlderVersion.healthy_volunteers
                  )
                }
              />
              <RadioMockup
                name="YesTwo"
                label="Yes"
                checked={trialNewerVersion.healthy_volunteers}
                changedNew={
                  trialNewerVersion.healthy_volunteers &&
                  !(
                    trialNewerVersion.healthy_volunteers ===
                    trialOlderVersion.healthy_volunteers
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.divider} />
        <div>
          <div className={styles.paneTitle}>Participants must</div>
          {baseInclusionCriteriaOne?.map(label => (
            <PillMockup
              label={label}
              changedOld={
                !baseInclusionCriteriaOne.includes(label) &&
                baseInclusionCriteriaTwo.includes(label)
              }
            />
          ))}
        </div>
        <div>
          <div className={styles.paneTitle}>Participants must</div>
          {baseInclusionCriteriaTwo?.map(label => (
            <PillMockup
              label={label}
              changedNew={
                !baseInclusionCriteriaOne.includes(label) &&
                baseInclusionCriteriaTwo.includes(label)
              }
            />
          ))}
        </div>
      </div>
    </TabPanel>
  );
};

export { Eligibility as default };
