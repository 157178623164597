/* eslint-disable camelcase */
import styles from './Forms.module.scss';
import General from 'sections/admin/trialEdit/General';
import Eligibility from 'sections/admin/trialEdit/Eligibility';
import TrialType from 'sections/admin/trialEdit/TrialType';
import PotentialBenefits from 'sections/admin/trialEdit/PotentialBenefits';
import OutcomeMeasures from 'sections/admin/trialEdit/OutcomeMeasures';
import TrialContent from 'components/admin/trialEdit/TrialContent/TrialContent';
import TrialHistory from 'sections/admin/trialEdit/TrialHistory';
import TrialComments from 'sections/admin/trialEdit/TrialComments';
import React from 'react';
import classNames from 'classnames';
import Card from '@moonshineragency/ui/src/components/Card/Card';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import { useTabState, Tab, TabList } from 'reakit/Tab';

const Forms = ({
  nctId,
  trial,
  trialForm,
  rawDataForm,
  benefitsData,
  interventionsData,
  measuresData,
  injuriesData,
  mechanisms,
  onChange,
  trialHistoryData,
  trialCommentsData,
  versions,
}) => {
  const tabInfo = useTabState({});
  const tabCommentsHistory = useTabState({});

  return (
    <div className={styles.container}>
      <Card>
        <TabList className={styles.tabs} {...tabInfo} aria-label="Study Editor">
          <Tab
            {...tabInfo}
            className={classNames(styles.tab, {
              [styles.tabActive]: tabInfo.selectedId === 'general',
            })}
            id="general"
          >
            <Content noSpacing theme="neutral" size="uiText100">
              General
            </Content>
          </Tab>
          <Tab
            {...tabInfo}
            className={classNames(styles.tab, {
              [styles.tabActive]: tabInfo.selectedId === 'eligibility',
            })}
            id="eligibility"
          >
            <Content noSpacing theme="neutral" size="uiText100">
              Eligibility
            </Content>
          </Tab>
          <Tab
            {...tabInfo}
            className={classNames(styles.tab, {
              [styles.tabActive]: tabInfo.selectedId === 'trial-type',
            })}
            id="trial-type"
          >
            <Content noSpacing theme="neutral" size="uiText100">
              Trial type
            </Content>
          </Tab>
          <Tab
            {...tabInfo}
            className={classNames(styles.tab, {
              [styles.tabActive]: tabInfo.selectedId === 'potential-benefits',
            })}
            id="potential-benefits"
          >
            <Content noSpacing theme="neutral" size="uiText100">
              Potential benefits
            </Content>
          </Tab>
          <Tab
            {...tabInfo}
            className={classNames(styles.tab, {
              [styles.tabActive]: tabInfo.selectedId === 'outcome-measures',
            })}
            id="outcome-measures"
          >
            <Content noSpacing theme="neutral" size="uiText100">
              Outcome measures
            </Content>
          </Tab>
        </TabList>
        <General tab={tabInfo} form={trialForm} />
        <Eligibility
          injuriesData={injuriesData}
          tab={tabInfo}
          form={trialForm}
          onChange={onChange}
        />
        <TrialType
          interventionsData={interventionsData}
          tab={tabInfo}
          form={trialForm}
          onChange={onChange}
        />
        <PotentialBenefits
          benefitsData={benefitsData}
          mechanisms={mechanisms}
          tab={tabInfo}
          form={trialForm}
          onChange={onChange}
        />
        <OutcomeMeasures
          measuresData={measuresData}
          tab={tabInfo}
          form={trialForm}
          onChange={onChange}
        />
      </Card>
      <TrialContent form={rawDataForm} tabInfo={tabInfo} />
      <Card>
        <TabList
          className={styles.tabs}
          {...tabCommentsHistory}
          aria-label="Comments"
        >
          <Tab
            {...tabCommentsHistory}
            className={classNames(styles.tab, {
              [styles.tabActive]: tabCommentsHistory.selectedId === 'comments',
            })}
            id="comments"
          >
            <Content noSpacing theme="neutral" size="uiText100">
              Comments
            </Content>
          </Tab>
          <Tab
            {...tabCommentsHistory}
            className={classNames(styles.tab, {
              [styles.tabActive]: tabCommentsHistory.selectedId === 'history',
            })}
            id="history"
          >
            <Content noSpacing theme="neutral" size="uiText100">
              History
            </Content>
          </Tab>
        </TabList>
        {tabCommentsHistory.selectedId === 'comments' && (
          <TrialComments
            trialCommentsData={trialCommentsData}
            trialForm={trialForm}
          />
        )}
        {tabCommentsHistory.selectedId === 'history' && (
          <TrialHistory
            trialHistoryData={trialHistoryData}
            nctId={nctId}
            trial={trial}
            injuriesData={injuriesData}
            interventionsData={interventionsData}
            benefitsData={benefitsData}
            mechanismsData={mechanisms}
            versions={versions}
            trialCommentsData={trialCommentsData}
            trialForm={trialForm}
          />
        )}
      </Card>
    </div>
  );
};

export default Forms;
